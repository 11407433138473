<template>
    <div id="app">
        <choose-wallet-modal
            :showWalletOptions="showWalletOptions"
            @close="(showWalletOptions = false), (noWallet = false), (showProceedToMetamaskModal = false)"
            @setWalletAndAddress="setWalletOption(getWalletOption())"
            :showProceedToMetamaskModal="showProceedToMetamaskModal"
            :noWallet="noWallet"
        />
        <notification-list />
        <router-view :startAutoClaim="startAutoClaim" @changeWalletRequest="showWalletOptions = true" />
        <footer>
            <div>Copyright © 2021. All rights reserved.</div>
        </footer>
    </div>
</template>

<script>
    import Core from "../src/core/core.js";
    import NotificationList from "../src/assets/components/NotificationList.vue";
    import MultiLang from "./core/multilang.js";
    import config from "./Config.json";
    import ChooseWalletModal from "./assets/components/ChooseWalletModal.vue";
    const timer = (ms) => new Promise((res) => setTimeout(res, ms));
    export default {
        data: function () {
            return {
                lang: new MultiLang(this),
                startAutoClaim: false,
                showWalletOptions: false,
                noWallet: false,
                showProceedToMetamaskModal: false,
            };
        },
        components: {
            NotificationList,
            ChooseWalletModal,
        },
        methods: {
            checkWalletOption(wallet) {
                let _this = this;

                if (wallet === "metamask") {
                    window.ethereum
                        .request({ method: "eth_accounts" })
                        .then(handleAccountsChanged)
                        .catch((err) => {
                            localStorage.removeItem("address");
                        });

                    window.ethereum.on("accountsChanged", handleAccountsChanged);

                    function handleAccountsChanged(accounts) {
                        if (accounts.length > 0) {
                            _this.walletUnlocked = true;
                        }
                    }
                }
            },

            setWalletOption(wallet) {
                let _this = this;

                this.selectedWallet = wallet;

                if (wallet) {
                    localStorage.setItem("selectedWallet", wallet);
                }
                if (window.ethereum && this.selectedWallet === "metamask") {
                    window.ethereum
                        .request({ method: "eth_requestAccounts" })
                        .then(handleAccountsChanged)
                        .catch((err) => {
                            console.log(err);
                            localStorage.removeItem("address");
                        });

                    // Note that this event is emitted on page load.
                    // If the array of accounts is non-empty, you're already
                    // connected.
                    window.ethereum.on("accountsChanged", handleAccountsChanged);

                    // For now, 'eth_accounts' will continue to always return an array
                    function handleAccountsChanged(accounts) {
                        let currentAccount = localStorage.getItem("address");
                        if (accounts.length === 0) {
                            _this.walletUnlocked = false;
                            localStorage.removeItem("address");
                            _this.$store.commit("setCurrentAddress", "");

                            // MetaMask is locked or the user has not connected any accounts
                            // alert('Please connect to MetaMask.');
                        } else if (accounts[0] !== currentAccount) {
                            currentAccount = accounts[0];
                            localStorage.setItem("address", currentAccount);

                            // _this.$root.core.setLangForAddress(localStorage.getItem("lang"), localStorage.getItem('address'));
                            location.reload();
                        } else if (accounts.length > 0) {
                            _this.walletUnlocked = true;
                        }
                    }
                } else if (window.localStorage.getItem("selectedWallet") === "walletconnect") {
                    if (!this.$root.core) {
                    } else {
                        this.$root.core.init();
                    }
                } else {
                    _this.noWallet = true;
                }
            },

            getWalletOption() {
                const selWallet = localStorage.getItem("selectedWallet");
                if (!selWallet) {
                    this.showWalletOptions = true;
                    return null;
                } else {
                    return selWallet;
                }
            },
        },

        async mounted() {
            this.lang.init();

            window.addEventListener("message", async function (e) {
                if (e.data && e.data.data && e.data.data.data && e.data.data.data.method === "metamask_unlockStateChanged") {
                    this.walletUnlocked = e.data.data.data.params.isUnlocked;
                }
            });

            // let connected = await window.ethereum.isConnected();
            // const chainId = await window.ethereum.request({ method: 'eth_chainId' });
            // if(connected && chainId !== "0x61") {

            // }

            let tronConnectAttemptsCounter = 0;
            let _this = this;
            const walletOpiton = _this.getWalletOption();
            setTimeout(async function initContract() {
                try {
                    // _this.$root.core = new Core(_this, )

                    if (walletOpiton) {
                        if (walletOpiton === "metamask" && !window.ethereum) {
                            throw Error("No Metamask extension found");
                        }

                        if (window.localStorage.getItem("selectedWallet") === "metamask") {
                            //won't commit address without these checks
                            if (window.ethereum) {
                                window.ethereum.on("chainChanged", async (_chainId) => {
                                    _chainId = Number(_chainId);
                                    if (config.SUPPORTED_BLOCKCHAINS.indexOf(_chainId) < 0) {
                                        alert(
                                            `${
                                                config.BLOCKCHAINS[_chainId] ? config.BLOCKCHAINS[_chainId] : "Current chain"
                                            }  is not suppoted. Please accept switching to ${config.PRIMARY_BLOCKCHAIN.name}`
                                        );
                                        await _this.$root.core.changeNetwork(this, config.PRIMARY_BLOCKCHAIN.symbol);
                                        return;
                                    }
                                    console.log("heared that chain changed");
                                    await timer(1000);

                                    _this.$root.core = null;
                                    _this.$root.core = new Core(_this);

                                    _this.$root.core.fetchActiveClaims(currentAccount, 10000);
                                    _this.$root.core.fetchContractsReserves(10000);
                                    _this.startAutoClaim = true;
                                });
                                window.ethereum.on("isConnected", () => window.location.reload());
                            }
                            if (!window.ethereum.chainId) {
                                throw new Error("no chainId");
                            }

                            let currentAccount = localStorage.getItem("address");
                            _this.$store.commit("setCurrentAddress", currentAccount);

                            _this.setWalletOption(_this.getWalletOption());

                            // _this.$root.core.updateSiteStats(50000);
                            // _this.$root.core.getCurrentRate();
                            _this.$root.core = new Core(_this);

                            if (_this.$root.core === undefined) {
                                throw Error();
                            }
                            if (currentAccount) {
                                _this.$root.core.fetchActiveClaims(currentAccount, 10000);
                                _this.$root.core.fetchContractsReserves(10000);
                            } else {
                                _this.$root.core.fetchContractsReserves(10000);
                            }
                        } else if (window.localStorage.getItem("selectedWallet") === "walletconnect") {
                            const WC_Obj = JSON.parse(window.localStorage.getItem("walletconnect"));

                            let currentAccount = localStorage.getItem("address");
                            _this.$store.commit("setCurrentAddress", currentAccount);

                            _this.setWalletOption(_this.getWalletOption());

                            // _this.$root.core.updateSiteStats(50000);
                            // _this.$root.core.getCurrentRate();
                            _this.$root.core = new Core(_this);

                            if (_this.$root.core === undefined) {
                                throw Error();
                            } else {
                                _this.$root.core.providerInstance.on("chainChanged", async (_chainId) => {
                                    console.log("chainChanged");
                                    _chainId = Number(_chainId);
                                    if (config.SUPPORTED_BLOCKCHAINS.indexOf(_chainId) < 0) {
                                        alert(
                                            `${
                                                config.BLOCKCHAINS[_chainId] ? config.BLOCKCHAINS[_chainId] : "Current chain"
                                            }  is not suppoted. Please accept switching to ${config.PRIMARY_BLOCKCHAIN.name}`
                                        );
                                        await _this.$root.core.changeNetwork(_this, config.PRIMARY_BLOCKCHAIN.symbol);
                                        return;
                                    }
                                    _this.$root.core = null;
                                    _this.$root.core = new Core(_this);

                                    _this.$root.core.fetchActiveClaims(currentAccount, 10000);
                                    _this.$root.core.fetchContractsReserves(10000);
                                });

                                _this.$root.core.providerInstance.on("disconnect", (code, reason) => {
                                    console.log(`connector.on("disconnect")`, code, reason);

                                    _this.$root.core.onDisconnect();
                                });
                            }

                            if (currentAccount) {
                                _this.$root.core.fetchActiveClaims(currentAccount, 10000);
                                _this.$root.core.fetchContractsReserves(10000);
                            } else {
                                _this.$root.core.fetchContractsReserves(10000);
                            }
                            // _this.showWalletOptions = true;
                            _this.$root.core = new Core(_this);
                        } else {
                            _this.noWallet = true;
                            _this.$root.core = new Core(_this);
                            _this.$root.core.fetchContractsReserves(10000);
                        }
                    } else {
                        _this.$root.core = new Core(_this);
                        _this.$root.core.fetchContractsReserves(10000);
                    }
                } catch (ex) {
                    console.log(ex);
                    if (ex.message === "no chainId") {
                        setTimeout(initContract, 300);
                        return;
                    }
                    console.log(ex);
                    tronConnectAttemptsCounter++;
                    if (tronConnectAttemptsCounter > 5 && ex.message == "No Metamask extension found") {
                        _this.noWallet = true;
                        _this.$root.core = new Core(_this);
                        return;
                    }
                }
            }, 400);
        },

        watch: {
            walletUnlocked: function () {
                let _this = this;
                setTimeout(function check() {
                    if (_this.walletUnlocked === false) {
                        _this.checkWalletOption(_this.selectedWallet);
                        setTimeout(check, 1000);
                        return;
                    } else if (_this.walletUnlocked === true) {
                    }
                }, 0);
            },
            noWallet: function (next) {
                const body = document.body;
                if (next === true) {
                    body.style.overflow = "hidden";
                } else {
                    body.style.overflow = "auto";
                }
            },
            showWalletOptions: function (next) {
                const body = document.body;
                if (next === true) {
                    body.style.overflow = "hidden";
                } else {
                    body.style.overflow = "auto";
                }
            },
            showProceedToMetamaskModal: function (next) {
                const body = document.body;
                if (next === true) {
                    body.style.overflow = "hidden";
                } else {
                    body.style.overflow = "auto";
                }
            },
        },
    };
</script>
