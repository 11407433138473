<template>
    <div class="modal">
        <div class="modal_wrapper modal_wrapper__table">
            <div class="modal_header">
                <div class="title">Reserve</div>
                <button @click="$emit('closeReserveModal')" class="button_modal_close">
                    <i class="i-close"></i>
                </button>
            </div>
            <div class="modal_main">
                <div class="table_container">
                    <table v-if="reserves">
                        <thead>
                            <td>{{ lang.get("ASSET") }}</td>
                            <td>{{ lang.get("DESTINATION_CHAIN") }}</td>
                            <td>{{ lang.get("EXIT_LIQUIDITY") }}</td>
                        </thead>
                        <tbody>
                            <tr v-for="chainId in supportedBlockchains" :key="chainId">
                                <td>BRNG</td>
                                <td>{{ getBlockchainDetails(chainId) }}</td>
                                <td>{{ reserves[chainId] }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-else class="info_empty">
                        {{ lang.get("EMPTY_LIST") }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import MultiLang from "../../core/multilang.js";
    import conf from "../../Config.json";

    export default {
        data: function () {
            return {
                lang: new MultiLang(this),
                supportedBlockchains: conf.SUPPORTED_BLOCKCHAINS,
            };
        },
        computed: {
            ...mapState(["reserves", "currentChain"]),
        },
        methods: {
            getBlockchainDetails(chainId) {
                return conf.BLOCKCHAINS[chainId];
            },
        },
    };
</script>
