import { Contract, ethers } from "ethers";
import conf from "../Config.json";
import axios from "axios";
import { abi } from "../assets/abi/bridge.js";
import { tokenAbi } from "../assets/abi/tokenAbi";
import WalletConnectProvider from "@walletconnect/web3-provider";

const timer = (ms) => new Promise((res) => setTimeout(res, ms));

export default class Core {
    constructor(vueContext) {
        this.context = vueContext;
        this.baseURL = conf.baseUrl;
        this.init();
    }

    async init() {
        if (window.localStorage.getItem("selectedWallet") === "metamask" && window.ethereum) {
            const blockchain = Number(window.ethereum.chainId);
            this.primaryPovider = new ethers.providers.Web3Provider(window.ethereum, "any");
            for (let chainId of conf.SUPPORTED_BLOCKCHAINS) {
                this[`provider_${chainId}`] = new ethers.providers.JsonRpcProvider(`${conf[chainId].NODE}`);
                this[`bridge_${chainId}`] = new ethers.Contract(conf[chainId].BRIDGE_ADDRESS, abi, this[`provider_${chainId}`]).connect(
                    this[`provider_${chainId}`]
                );
                this[`token_${chainId}`] = new ethers.Contract(conf[chainId].BRNG_TOKEN_ADDRESS, tokenAbi, this[`provider_${chainId}`]).connect(
                    this[`provider_${chainId}`]
                );

                if (blockchain === Number(chainId)) {
                    this.provider = this.primaryPovider;
                    this.signer = this.provider.getSigner();
                    this.context.$store.commit("setChainId", chainId);
                    this.currentBlockchain = Number(blockchain);
                    this[`bridge_${chainId}`] = new ethers.Contract(conf[chainId].BRIDGE_ADDRESS, abi, this.provider).connect(this.signer);
                    this[`token_${chainId}`] = new ethers.Contract(conf[chainId].BRNG_TOKEN_ADDRESS, tokenAbi, this.provider).connect(this.signer);
                } else {
                    this[`bridge_${chainId}`].connect(this[`provider_${chainId}`]);
                    this[`token_${chainId}`].connect(this[`provider_${chainId}`]);
                }
            }
        } else if (window.localStorage.getItem("selectedWallet") === "walletconnect") {
            const rpc = {};
            for (let chainId of conf.SUPPORTED_BLOCKCHAINS) {
                const url = conf.NETWORK_PARAMS.find((el) => Number(el.chainId) === Number(chainId))?.params.rpcUrls[0];
                rpc[chainId] = url;
            }
            this.providerInstance = new WalletConnectProvider({
                rpc: rpc,
            });
            if (!this.providerInstance.connected) {
                try {
                    // Create a dialogue
                    await this.providerInstance.enable();
                } catch (error) {
                    if (error.message === "User closed modal") {
                        window.localStorage.removeItem("address");
                        window.localStorage.removeItem("walletconnect");
                        window.localStorage.removeItem("selectedWallet");
                    }
                }
            }
            await this.subscribeToEvents();
            const WC_Obj = JSON.parse(window.localStorage.getItem("walletconnect"));
            const blockchain = Number(WC_Obj?.chainId);

            this.primaryPovider = new ethers.providers.Web3Provider(this.providerInstance, "any");

            for (let chainId of conf.SUPPORTED_BLOCKCHAINS) {
                this[`provider_${chainId}`] = new ethers.providers.JsonRpcProvider(`${conf[chainId].NODE}`);
                this[`bridge_${chainId}`] = new ethers.Contract(conf[chainId].BRIDGE_ADDRESS, abi, this[`provider_${chainId}`]).connect(
                    this[`provider_${chainId}`]
                );
                this[`token_${chainId}`] = new ethers.Contract(conf[chainId].BRNG_TOKEN_ADDRESS, tokenAbi, this[`provider_${chainId}`]).connect(
                    this[`provider_${chainId}`]
                );

                if (blockchain === Number(chainId)) {
                    this.provider = this.primaryPovider;
                    this.signer = this.provider.getSigner();
                    this.context.$store.commit("setChainId", chainId);
                    this.currentBlockchain = Number(blockchain);
                    this[`bridge_${chainId}`] = new ethers.Contract(conf[chainId].BRIDGE_ADDRESS, abi, this.provider).connect(this.signer);
                    this[`token_${chainId}`] = new ethers.Contract(conf[chainId].BRNG_TOKEN_ADDRESS, tokenAbi, this.provider).connect(this.signer);
                } else {
                    this[`bridge_${chainId}`].connect(this[`provider_${chainId}`]);
                    this[`token_${chainId}`].connect(this[`provider_${chainId}`]);
                }
            }
            // else {
            //     let provider = new ethers.providers.JsonRpcProvider("https://data-seed-prebsc-1-s1.binance.org:8545/");;
            //     this.provider = provider;
            //     // this.signer = provider.getSigner();
            //     return true
            // }
            return true;
        } else if (!window.localStorage.getItem("selectedWallet")) {
            for (let chainId of conf.SUPPORTED_BLOCKCHAINS) {
                this[`provider_${chainId}`] = new ethers.providers.JsonRpcProvider(`${conf[chainId].NODE}`);
                this[`bridge_${chainId}`] = new ethers.Contract(conf[chainId].BRIDGE_ADDRESS, abi, this[`provider_${chainId}`]).connect(
                    this[`provider_${chainId}`]
                );
                this[`token_${chainId}`] = new ethers.Contract(conf[chainId].BRNG_TOKEN_ADDRESS, tokenAbi, this[`provider_${chainId}`]).connect(
                    this[`provider_${chainId}`]
                );
            }
            console.log(this);
            return true;
        }
    }

    fetchActiveClaims(userAddress, period = 5000) {
        let _this = this;
        setTimeout(async function tick() {
            try {
                const activeClaims = await _this.getActiveClaims(userAddress);
                const transactions = await _this.getTransactionsHistory(userAddress);

                _this.context.$store.commit("setActiveClaims", activeClaims); //TODO: add check active claims from contract
                _this.context.$store.commit("setTransactionsHistory", transactions);

                setTimeout(tick, period);

                return;
            } catch (ex) {
                console.log(ex);
                setTimeout(tick, period);
                return;
            }
        }, 300);
    }

    fetchContractsReserves(period = 10000) {
        let _this = this;
        setTimeout(async function tick() {
            try {
                const res = await _this.getBalancesFromContracts(); //TODO: FIX
                _this.context.$store.commit("setReserves", res);

                setTimeout(tick, period);

                return;
            } catch (ex) {
                console.log(ex);
                setTimeout(tick, period);
                return;
            }
        }, 300);
    }

    getOptimalGasPrice(period = 60000 /* miliseconds */) {
        let _this = this;
        setTimeout(async function tick() {
            let currentGasPrice;
            const now = new Date();
            const requestTimestampUtc = Date.UTC(
                now.getUTCFullYear(),
                now.getUTCMonth(),
                now.getUTCDate(),
                now.getUTCHours(),
                now.getUTCMinutes(),
                now.getUTCSeconds(),
                now.getUTCMilliseconds()
            );
            try {
                if (localStorage.getItem("gasPrice") && localStorage.getItem("gasPriceTimeStamp")) {
                    if (localStorage.getItem("gasPriceTimeStamp") && requestTimestampUtc <= +localStorage.getItem("gasPriceTimeStamp") + 600000) {
                        _this.context.$store.commit("setGasPrice", localStorage.getItem("gasPrice"));
                        _this.gasPrice = localStorage.getItem("gasPrice");
                    } else if (localStorage.getItem("gasPriceTimeStamp") && requestTimestampUtc > +localStorage.getItem("gasPriceTimeStamp") + 600000) {
                        currentGasPrice = await _this.fetchGasPrice();
                        localStorage.setItem("gasPrice", currentGasPrice);
                        localStorage.setItem("gasPriceTimeStamp", requestTimestampUtc);
                        _this.context.$store.commit("setGasPrice", currentGasPrice);
                        _this.gasPrice = currentGasPrice.toString();
                    }
                } else {
                    currentGasPrice = await _this.fetchGasPrice();
                    localStorage.setItem("gasPrice", currentGasPrice);
                    localStorage.setItem("gasPriceTimeStamp", requestTimestampUtc);
                    _this.context.$store.commit("setGasPrice", currentGasPrice);
                    _this.gasPrice = currentGasPrice.toString();
                }
                setTimeout(tick, period);
                return;
            } catch (ex) {
                console.log(ex);
                setTimeout(tick, period);
                return;
            }
        }, 300);
    }

    async fetchGasPrice() {
        const res = await axios.get("https://ethgasstation.info/api/ethgasAPI.json?");
        return res.data.fast;
    }

    async subscribeToEvents() {
        try {
            if (!this.providerInstance) {
                return;
            }

            this.providerInstance.on("accountsChanged", async (accounts) => {
                let currentAccount = window.localStorage.getItem("address");
                console.log(`connector.on("accountsChanged")`);

                const address = accounts[0];

                if (!currentAccount || address.toLowerCase() !== currentAccount.toLowerCase()) {
                    currentAccount = address;
                    localStorage.setItem("address", currentAccount);

                    // _this.$root.core.setLangForAddress(localStorage.getItem("lang"), localStorage.getItem('address'));
                    location.reload();
                } else if (address.toLowerCase() === currentAccount.toLowerCase()) {
                    this.context.$store.commit("setCurrentAddress", address);
                }
            });
            if (this.providerInstance.connected) {
                const { chainId, accounts } = this.providerInstance;

                this.onSessionUpdate(accounts, chainId);
            }
        } catch (error) {
            console.log(error);
        }
    }
    async killSession() {
        await this.providerInstance.disconnect();
    }

    async onDisconnect() {
        window.localStorage.removeItem("address");
        window.localStorage.removeItem("walletconnect");
        window.location.reload();
    }

    async onSessionUpdate(accounts, chainId) {
        let currentAccount = localStorage.getItem("address");
        const address = accounts[0];
        if (!currentAccount || address.toLowerCase() !== currentAccount.toLowerCase()) {
            currentAccount = address;
            localStorage.setItem("address", currentAccount);

            // _this.$root.core.setLangForAddress(localStorage.getItem("lang"), localStorage.getItem('address'));
            location.reload();
        } else if (address.toLowerCase() === currentAccount.toLowerCase()) {
            this.currentBlockchain = Number(chainId);
            this.context.$store.commit("setCurrentBlockchain", this.currentBlockchain);
            this.context.$store.commit("setCurrentAddress", address);
        }
    }

    async getClaimHash(hash) {
        const res = await axios.get("/getMessageByTransactionHash", {
            baseURL: conf.baseUrl,
            params: {
                hash: hash,
            },
        });
        return res.data;
    }

    async signMessage(hash) {
        await axios.post(`${conf.baseUrl}signMessage`, {
            hash: hash,
            originId: this.currentBlockchain,
        });
    }

    async setClaimHash(lock_hash, claim_hash) {
        await axios.post(`${conf.baseUrl}setClaimHash`, {
            lock_hash,
            claim_hash,
        });
    }

    async claimTokens(lockIdx, recipient, amount, source, signature, destination) {
        try {
            console.log("calling method");
            const chainId = destination === "BSC" ? "56" : destination === "ETH" ? "1" : destination === "HARM" ? "1666600000" : "";
            const sourceBytes = ethers.utils.formatBytes32String(source);

            const rawRes = await this[`bridge_${chainId}`].claim(lockIdx, recipient, amount, sourceBytes, signature);
            return rawRes;
        } catch (error) {
            console.log(error);
            this.context.$store.commit("push_notification", {
                type: "",
                typeClass: "danger",
                message: `${error.data ? (error.data.message ? error.data.message : error.message) : error.message ? error.message : error.error}`,
            });
        }
    }

    async changeNetwork(context, blockchain) {
        const selectedWallet = window.localStorage.getItem("selectedWallet");
        let _this = context;
        const networkObject = conf.NETWORK_PARAMS.find((el) => el.symbol === blockchain);

        const params = [
            {
                chainId: networkObject.params.chainId,
                chainName: networkObject.params.chainName,
                nativeCurrency: networkObject.params.nativeCurrency,
                rpcUrls: networkObject.params.rpcUrls,
                blockExplorerUrls: networkObject.params.blockExplorerUrls,
            },
        ];
        const switchParams = [{ chainId: networkObject.params.chainId }];

        if (selectedWallet && selectedWallet === "metamask") {
            if (window.ethereum) {
                try {
                    await window.ethereum.request({
                        method: "wallet_switchEthereumChain",
                        params: switchParams,
                    });

                    const highestId = window.setTimeout(() => {
                        for (let i = highestId; i >= 0; i--) {
                            // console.log(i);
                            window.clearInterval(i);
                        }
                    }, 0);
                    console.log("chain changed");
                } catch (switchError) {
                    // This error code indicates that the chain has not been added to MetaMask.
                    if (switchError.code === 4902 || switchError?.code?.toString() === "-32603") {
                        try {
                            await window.ethereum.request({
                                method: "wallet_addEthereumChain",
                                params: params,
                            });

                            const highestId = window.setTimeout(() => {
                                for (let i = highestId; i >= 0; i--) {
                                    // console.log(i);
                                    window.clearInterval(i);
                                }
                            }, 0);
                        } catch (addError) {
                            console.log(addError);
                        }
                    }

                    // handle other "switch" errors
                }
            } else {
                alert("Please install metamask wallet extension");
            }
        } else if (selectedWallet && selectedWallet === "walletconnect") {
            try {
                await this.provider.provider.request({
                    method: "wallet_switchEthereumChain",
                    params: switchParams,
                });

                const highestId = window.setTimeout(() => {
                    for (let i = highestId; i >= 0; i--) {
                        // console.log(i);
                        window.clearInterval(i);
                    }
                }, 0);
            } catch (switchError) {
                // This error code indicates that the chain has not been added to MetaMask.
                if (switchError.code === 4902 || switchError?.code?.toString() === "-32603" || switchError.toString().includes("Unrecognized chain")) {
                    try {
                        await this.provider.provider.request({
                            method: "wallet_addEthereumChain",
                            params: params,
                        });

                        const highestId = window.setTimeout(() => {
                            for (let i = highestId; i >= 0; i--) {
                                // console.log(i);
                                window.clearInterval(i);
                            }
                        }, 0);
                    } catch (addError) {
                        console.log(addError);
                    }
                }
                console.log(switchError);

                // handle other "switch" errors
            }
        }
    }

    withoutRound(number, roundTo = 2) {
        if (roundTo === 2) {
            if (number.toString().indexOf(".") !== -1) {
                const splittedNumber = number.toString().split(".");
                splittedNumber[1] += "00";
                number = splittedNumber.join(".");

                return number.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
            } else {
                number = number.toString() + ".00";
                return number.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
            }
        } else if (roundTo === 4) {
            if (number.toString().indexOf(".") !== -1) {
                const splittedNumber = number.toString().split(".");
                splittedNumber[1] += "00";
                number = splittedNumber.join(".");

                return number.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
            } else {
                number = number.toString() + ".00";
                return number.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
            }
        }
    }

    async approve(chainId, amount, userAddress) {
        let allowance = ethers.utils.formatUnits(await this[`token_${chainId}`].allowance(userAddress, this[`bridge_${chainId}`].address), "ether");
        if (Number(allowance) >= Number(amount)) {
            return true;
        } else {
            try {
                let maxAllowance = ethers.BigNumber.from(2).pow(256).sub(1);
                const rawTransaction = await this[`token_${chainId}`].approve(this[`bridge_${chainId}`].address, maxAllowance);

                this.context.$store.commit("push_notification", {
                    type: "",
                    typeClass: "danger",
                    message: `Please wait for blockchain confirmation`,
                    header: "Processing your transaction.",
                });
                return rawTransaction;
            } catch (error) {
                this.context.$store.commit("push_notification", {
                    type: "",
                    typeClass: "danger",
                    message: `${error.data && error.data.message ? error.data.message : error.message ? error.message : `error, please contract support`}`,
                    header: "Error occured",
                });
                return false;
            }
        }
    }

    async makeSwap(chainId, userAddress, receiverAddress, amount, blockchainId) {
        // with 2 args  0x496a36f2

        amount = ethers.utils.parseEther(`${amount}`);
        blockchainId = ethers.utils.formatBytes32String(blockchainId);

        if (userAddress.toLowerCase() == receiverAddress.toLowerCase()) {
            try {
                const rawResult = await this[`bridge_${chainId}`]["lock(uint256,bytes32)"](amount, blockchainId);
                this.context.$store.commit("push_notification", {
                    type: "",
                    typeClass: "danger",
                    message: `Please wait for blockchain confirmation`,
                    header: "Processing your transaction.",
                });

                return rawResult;
            } catch (error) {
                console.log(error);
                this.context.$store.commit("push_notification", {
                    type: "",
                    typeClass: "danger",
                    message: `${error.data && error.data.message ? error.data.message : error.message ? error.message : `error, please contract support`}`,
                    header: "Error occured",
                });
                return false;
            }
        } else {
            try {
                //with 3 args 0xca3369c6
                const rawResult = await this[`bridge_${chainId}`]["lock(address,uint256,bytes32)"](receiverAddress, amount, blockchainId, {
                    gasPrice: ethers.utils.parseUnits(conf[chainId].DEFAULT_GAS_PRICE_GWEI, "gwei"),
                });
                this.context.$store.commit("push_notification", {
                    type: "",
                    typeClass: "danger",
                    message: `Please wait for blockchain confirmation`,
                    header: "Processing your transaction.",
                });
                return rawResult;
            } catch (error) {
                console.log(error);
                this.context.$store.commit("push_notification", {
                    type: "",
                    typeClass: "danger",
                    message: `${error.data && error.data.message ? error.data.message : error.message ? error.message : `error, please contract support`}`,
                    header: "Error occured",
                });
            }
        }
    }

    async getActiveClaims(userAddress) {
        const res = await axios.get("/getPendingLocksForUser", {
            baseURL: conf.baseUrl,
            params: {
                userAddress,
            },
        });
        return res.data;
    }

    async requestClaim(claim) {
        const userBalance = await this.signer.getBalance();
        const parsedBalance = Number.parseFloat(userBalance);

        if (Number.parseInt(window.ethereum.chainId) === 97 && parsedBalance < 0.001) {
            this.context.$store.commit("push_notification", {
                type: "",
                typeClass: "danger",
                message: `Not enough BNB to pay the fees for transaciton`,
                header: "BNB Balance insufficient",
            });
            return;
        } else if (Number.parseInt(window.ethereum.chainId) === 3 && parsedBalance < 0.001) {
            this.context.$store.commit("push_notification", {
                type: "",
                typeClass: "danger",
                message: `Not enough ETH to pay the fees for transaciton`,
                header: "ETH Balance insufficient",
            });
            return;
        }
        try {
            const res = await this.bridgeContract.claim(claim.txnHash, { gasPrice: ethers.utils.parseUnits(Config.DEFAULT_GAS_PRICE_GWEI, "gwei") });

            this.context.$store.commit("push_notification", {
                type: "",
                typeClass: "danger",
                message: `Please wait for blockchain confirmation`,
                header: "Processing your transaction.",
            });

            return res;
        } catch (error) {
            this.context.$store.commit("push_notification", {
                type: "",
                typeClass: "danger",
                message: `${error.data && error.data.message ? error.data.message : error.message ? error.message : `error, please contract support`}`,
                header: "Error occured",
            });
        }
    }

    async getTransactionsHistory(userAddress) {
        let response;
        response = await axios.get("/getTransactionsList", {
            baseURL: this.baseURL,
            params: {
                address: userAddress,
            },
        });

        // const resultArr = response.data[0].map((el) => {
        //     if (el.is_claimed == 0) {
        //         return {
        //             ...el,
        //             txnType: "locked",
        //             render_id: (Math.random().toString(36) + Date.now().toString(36)).substr(2),
        //             render_time: el.lock_time,
        //         };
        //     } else {
        //         return [
        //             { ...el, txnType: "locked", render_id: (Math.random().toString(36) + Date.now().toString(36)).substr(2), render_time: el.lock_time },
        //             { ...el, txnType: "claimed", render_id: (Math.random().toString(36) + Date.now().toString(36)).substr(2), render_time: el.claimed_time },
        //         ];
        //     }
        // });

        // return resultArr.flat().sort((a, b) => b.render_time - a.render_time);
        return response.data[0];
    }

    async getBalancesFromContracts() {
        try {
            let result = {};
            for (let chainId of conf.SUPPORTED_BLOCKCHAINS) {
                await timer(500);

                const balanceRaw = await this[`token_${chainId}`].balanceOf(this[`bridge_${chainId}`].address);

                const balance = Number(ethers.utils.formatUnits(balanceRaw, "ether"));
                result[chainId] = balance;
            }
            return result;
        } catch (error) {
            console.log(error);
            // if (error.message.includes("call revert exception")) {
            //     console.log("here");
            //     await this.init();
            //     return;
            // }
        }
    }

    async ownerWithdraw(amount, chainId) {
        amount = ethers.utils.parseEther(`${amount}`);

        const res = await this[`bridge_${chainId}`]["withdraw(uint256)"](amount);
        res.wait();
    }
    // const balanceBinanceChainRaw = await this.brng.balanceOf(this.bnbContract);
    // const balanceEthereumChainRaw = await this.brng2.balanceOf(this.ethContract);

    // const balanceBinanceChain = Number(ethers.utils.formatUnits(balanceBinanceChainRaw, "ether"));
    // const balanceEthereumChain = Number(ethers.utils.formatUnits(balanceEthereumChainRaw, "ether"));

    // return {
    //     balanceBinanceChain,
    //     balanceEthereumChain,
    // };
}
