<template>
    <div class="modal">
        <div class="modal_wrapper modal_wrapper__table">
            <div class="modal_header">
                <div class="title">{{ lang.get("TRANSACTION_HISTORY") }}</div>
                <button @click="$emit('close')" class="button_modal_close">
                    <i class="i-close"></i>
                </button>
            </div>
            <div class="modal_main">
                <div class="header_history_table">
                    <button class="wrapper_input select_token" :class="{ active: showIntervals }">
                        <div @click="showIntervals = !showIntervals" class="input">
                            <div :class="{ input_active: transactionsPeriodInDays || transactionsPeriodInDays == 0 }">{{ lang.get(getNameOfPeriod) }}</div>
                            <i class="i-arrow-down"></i>
                        </div>
                        <ul v-if="showIntervals" class="container_select select_filter">
                            <li @click="(transactionsPeriodInDays = 1), (showIntervals = false)">{{ lang.get("HOURS") }}</li>
                            <li @click="(transactionsPeriodInDays = 7), (showIntervals = false)">{{ lang.get("DAYS") }}</li>
                            <li @click="(transactionsPeriodInDays = 0), (showIntervals = false)">{{ lang.get("ALL") }}</li>
                        </ul>
                    </button>
                    <div class="legend">
                        <div class="legend_claimed">Claimed</div>
                        <div class="legend_locked">Locked</div>
                    </div>
                </div>
                <div class="table_container">
                    <table v-if="showTransactionHistory.length">
                        <thead>
                            <td>{{ lang.get("NETWORK") }}</td>
                            <td>{{ lang.get("VALUE") }}</td>
                            <td>{{ lang.get("TXN_STATUS") }}</td>
                            <td>DATE</td>
                            <!-- <td>{{lang.get("VISIT")}}</td> -->
                        </thead>
                        <tbody>
                            <tr v-for="(transaction, index) in showTransactionHistory" :key="index">
                                <td class="td_conversion">
                                    <span class="name">{{ transaction.from_chain }}</span
                                    ><span>→</span><span class="name">{{ transaction.to_chain }}</span>
                                </td>

                                <td>{{ transaction.event_obj.amount }} BRNG</td>
                                <!-- <td><span :class="getBlockchainSource(transaction)">{{getBlockchainSource(transaction) == "blue" ? "ETH" : "BSC"}}</span> {{transaction.amount}} BRNG</td> -->
                                <td class="td_address">
                                    <a
                                        :href="redirectToExplorer(transaction)"
                                        :class="`${transaction.claim_hash !== '' ? 'claimed' : 'locked'}`"
                                        target="_blank"
                                        >{{ transactionShort(transaction) }}</a
                                    >
                                </td>
                                <td>{{ getDate(transaction) }}</td>
                                <!-- <td class="view_tx"><span><a href="#" target="_blank">view tx</a></span></td> -->
                            </tr>
                        </tbody>
                    </table>
                    <div v-else class="info_empty">
                        {{ lang.get("EMPTY_LIST") }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import MultiLang from "../../core/multilang.js";
    export default {
        data: function () {
            return {
                transactionsPeriodInDays: null,
                showIntervals: false,
                lang: new MultiLang(this),
            };
        },
        computed: {
            ...mapState(["transactionsHistory"]),
            showTransactionHistory() {
                this.transactionsHistory.sort((a, b) => b.event_obj.block_timestamp - a.event_obj.block_timestmp);
                if (this.transactionsPeriodInDays == null || this.transactionsPeriodInDays == 0) {
                    return this.transactionsHistory;
                } else if (this.transactionsPeriodInDays == 1) {
                    return this.transactionsHistory.filter((el) => el.event_obj.block_timestamp >= Math.floor(new Date().getTime() / 1000) - 86400);
                } else if (this.transactionsPeriodInDays == 7) {
                    return this.transactionsHistory.filter((el) => el.event_obj.block_timestamp >= Math.floor(new Date().getTime() / 1000) - 604800);
                }
            },
            getNameOfPeriod() {
                if (this.transactionsPeriodInDays == 0) {
                    return "ALL";
                } else if (this.transactionsPeriodInDays == 1) {
                    return "HOURS";
                } else if (this.transactionsPeriodInDays == 7) {
                    return "DAYS";
                } else if (this.transactionsPeriodInDays == null) {
                    return "TIME_INTERVAL";
                }
            },
        },
        methods: {
            transactionShort(txn) {
                if (txn.claim_hash === "") {
                    return txn.lock_hash.slice(0, 5) + "..." + txn.lock_hash.slice(txn.lock_hash.length - 5, txn.lock_hash.length);
                } else {
                    return txn.claim_hash.slice(0, 5) + "..." + txn.claim_hash.slice(txn.claim_hash.length - 5, txn.claim_hash.length);
                }
            },
            getDate(txn) {
                return txn.event_obj.block_timestamp // TODO change to redgister time or time from lock event
                    ? new Date(txn.event_obj.block_timestamp * 1000).toLocaleString("en-EN", {
                          hour: "numeric",
                          minute: "numeric",

                          year: "numeric",
                          month: "short",
                          day: "numeric",
                      })
                    : 0;
                //TODO: discuss what time to describe
                if (txn.txnType == "locked") {
                    return txn.render_time // TODO change to redgister time or time from lock event
                        ? new Date(txn.render_time * 1000).toLocaleString("en-EN", {
                              hour: "numeric",
                              minute: "numeric",

                              year: "numeric",
                              month: "short",
                              day: "numeric",
                          })
                        : 0;
                } else if (txn.txnType == "claimed") {
                    return txn.render_time
                        ? new Date(txn.render_time * 1000).toLocaleString("en-EN", {
                              hour: "numeric",
                              minute: "numeric",

                              year: "numeric",
                              month: "short",
                              day: "numeric",
                          })
                        : 0;
                }
            },
            redirectToExplorer(transaction) {
                if (transaction.claim_hash === "") {
                    return transaction.from_chain === "BSC"
                        ? `https://bscscan.com/tx/${transaction.lock_hash}`
                        : transaction.from_chain === "HARM"
                        ? `https://explorer.harmony.one/tx/${transaction.lock_hash}`
                        : transaction.from_chain === "ETH"
                        ? `https://etherscan.io/tx/${transaction.lock_hash}`
                        : "";
                } else {
                    return transaction.to_chain === "BSC"
                        ? `https://bscscan.com/tx/${transaction.claim_hash}`
                        : transaction.to_chain === "HARM"
                        ? `https://explorer.harmony.one/tx/${transaction.claim_hash}`
                        : transaction.to_chain === "ETH"
                        ? `https://etherscan.io/tx/${transaction.claim_hash}`
                        : "";
                }
            },
            getBlockchainSource(txn) {
                if (txn.txnType == "locked") {
                    return txn.source == "0x3" ? "blue" : "yellow";
                } else if (txn.txnType == "claimed") {
                    return txn.destination == "0x3" ? "blue" : "yellow";
                }
            },
            getBlockchainName(chainId) {
                return chainId == "0x3" ? "ETH" : "BSC";
            },
        },
    };
</script>
