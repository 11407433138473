<template>
    <div class="modal">
            <div class="modal_wrapper ">
                <div class="modal_header">
                    <div class="title">{{lang.get("SOCIAL_NETWORK_TITLE")}}</div>
                    <button @click="$emit('close')" class="button_modal_close">
                        <i class="i-close"></i>
                    </button>
                </div>
                <div class="modal_main modal_select">
                    <ul class="links">
                        <li><i class="i-telegram"></i> <a href="https://t.me/bRing_channel">Telegram Channel</a></li>
                        <li><i class="i-telegram"></i> <a href="https://t.me/bRing_community">Telegram Community</a></li>
                        <li><span class="i-twitter"></span> <a href="https://twitter.com/BringFinance">Twitter</a></li>
                        <li><i class="i-medium"></i> <a href="https://medium.com/@BringFinance">Medium</a></li>
                    </ul>
                </div>
            </div>
    </div>
</template>


<script>
import MultiLang from '../../core/multilang.js'
export default {
    data: function(){
        return{
            lang: new MultiLang(this),
        }
    },
}
</script>