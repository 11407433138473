<template>
    <div class="modal">
        <transfering-modal
            v-if="
                showTransactionModal &&
                (pendingTransaction === true || successTransaction === true || approvePending === true || approveSuccess === true || claimSuccess)
            "
            :blockchainA="blockchainA"
            :blockchainB="blockchainB"
            :receiverAddress="receiverAddress"
            :sendAmount="sendTokenAmount"
            :pendingTransaction="pendingTransaction"
            :transactionHash="transactionHash"
            :successTransaction="successTransaction"
            :approvePending="approvePending"
            :approveSuccess="approveSuccess"
            :disabled="disabled"
            :claimTnx="claimTnx"
            :claimSuccess="claimSuccess"
            :selectedClaim="selectedClaim"
            @closeClaimNotif="(selectedClaim = {}), (claimSuccess = false), (claimFailure = false), (claimTnx = '')"
            @claimLock="claim()"
        />
        <div class="modal_wrapper">
            <div class="modal_header">
                <div class="title">{{ lang.get("SEND_AMOUNT") }}</div>
                <button @click="$emit('closeTransModal')" class="button_modal_close">
                    <i class="i-close"></i>
                </button>
            </div>
            <div class="modal_main">
                <div class="modal_block">
                    <div class="wrapper_input">
                        <div class="input_title">
                            <span>{{ lang.get("YOU_SEND") }}</span>
                            <span>{{ lang.get("BALANCE") }}: {{ tokenBalance }} BRNG</span>
                        </div>
                        <div class="input">
                            <input type="number" placeholder="Enter BRNG amount you want to swap" v-model="sendTokenAmount" />
                            <button @click="setMaxSendAmount" class="button button_max">{{ lang.get("MAX") }}</button>
                        </div>
                    </div>
                    <div class="button_send_to">
                        <i class="i-arrow-to arrow-down"></i>
                    </div>
                    <div class="wrapper_input">
                        <div class="input_title">
                            <span>{{ lang.get("YOU_RECEIVE") }}</span>
                        </div>
                        <div class="input">
                            <div>{{ sendTokenAmount }}</div>
                            <!-- <button class="button button_max">Max</button> -->
                        </div>
                    </div>

                    <div class="modal_warning" v-if="reserveLack">
                        <i class="i-danger"></i>
                        Warning! Swap amount outnumbers current reserves in chain {{ this.blockchainB.NAME }}. Current reserve is :
                        {{ reserves[blockchainB.CHAIN_ID] }} BRNG
                    </div>

                    <div class="table_info">
                        <div class="table_info-item">
                            <span>{{ lang.get("ESTIMATED_FEE") }}:</span>
                            <span>0 BRNG</span>
                        </div>
                    </div>

                    <button @click="makeSwap()" class="button button_swap">{{ lang.get("SWAP") }}</button>
                    <!-- <button :disabled="disableConnectButton" @click="connectWallet()" class="button button_connect-wallet">Connect wallet</button> -->
                </div>

                <div class="modal_block">
                    <div class="several_inputs_container">
                        <div class="wrapper_input">
                            <div class="input_title">BRNG</div>
                            <div class="input">
                                <div class="label">
                                    <div
                                        class="network-icon"
                                        :class="{
                                            icon1: blockchainA.SYMBOL === 'eth',
                                            icon2: blockchainA.SYMBOL === 'BSC',
                                            icon3: blockchainA.SYMBOL === 'HARM',
                                        }"
                                    ></div>
                                    <div class="label_text">{{ blockchainA.NAME }}</div>
                                </div>
                            </div>
                        </div>
                        <i class="i-arrow-to"></i>
                        <div class="wrapper_input">
                            <div class="input_title"><span>BRNG</span></div>
                            <div class="input">
                                <div class="label">
                                    <div
                                        class="network-icon"
                                        :class="{
                                            icon1: blockchainB.SYMBOL === 'eth',
                                            icon2: blockchainB.SYMBOL === 'BSC',
                                            icon3: blockchainB.SYMBOL === 'HARM',
                                        }"
                                    ></div>
                                    <div class="label_text">{{ blockchainB.NAME }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="wrapper_input recipient_address">
                        <div class="input_title">{{ lang.get("RECIPIENT_ADDRESS") }}</div>
                        <div class="input">
                            <input class="input_active" disabled :value="receiverAddress" />
                            <button class="button_delete_address"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { ethers } from "ethers";
    import TransferingModal from "./TransferingModal.vue";
    import MultiLang from "../../core/multilang.js";
    import conf from "../../Config.json";
    const timer = (ms) => {
        console.log("delay");
        return new Promise((res) => setTimeout(res, ms));
    };
    export default {
        components: { TransferingModal },
        props: ["blockchainA", "blockchainB", "receiverAddress", "startAutoClaim"],
        data: function () {
            return {
                lang: new MultiLang(this),
                tokenBalance: "",
                sendTokenAmount: "",
                receiveTokenAmount: 0,
                showTransactionModal: false,
                pendingTransaction: false,
                successTransaction: false,
                claimSuccess: false,
                approvePending: false,
                approveSuccess: false,
                transactionHash: "",
                claimTnx: "",
                selectedClaim: {},
                changeNetworkButton: false,
                claimLockButton: false,
                disabled: false,
            };
        },
        computed: {
            ...mapState(["currentAddress", "reserves", "currentChain"]),
            reserveLack() {
                if (this.reserves && this.sendTokenAmount) {
                    return this.sendTokenAmount > this.reserves[this.blockchainB.CHAIN_ID] ? true : false;
                }
                return false;
            },
            chainSymbol() {
                return conf[this.currentChain] ? conf[this.currentChain].SYMBOL : "";
            },
        },
        mounted() {
            let _this = this;
            setTimeout(async function updateComponentData() {
                try {
                    await _this.getUserBalance();

                    setTimeout(updateComponentData, 10000);
                    return;
                } catch (error) {
                    console.log(error);
                    setTimeout(updateComponentData, 1000);
                    return;
                }
            });
        },
        methods: {
            async getUserBalance() {
                let result = await this.$root.core[`token_${this.blockchainA.CHAIN_ID}`].balanceOf(this.currentAddress);
                this.tokenBalance = this.$root.core.withoutRound(ethers.utils.formatUnits(result._hex, "ether"));
            },
            setMaxSendAmount() {
                this.sendTokenAmount = Math.min(this.tokenBalance, this.reserves[this.blockchainB.CHAIN_ID]);
            },

            async makeSwap() {
                try {
                    if (Number(this.sendTokenAmount) < 1) {
                        this.$store.commit("push_notification", {
                            type: "",
                            typeClass: "danger",
                            message: `Swap amount should be more than 1 token.`,
                            header: "Error occured",
                        });
                        return;
                    }
                    if (Number(this.sendTokenAmount) > Number(this.tokenBalance)) {
                        this.$store.commit("push_notification", {
                            type: "",
                            typeClass: "danger",
                            message: `Entered amount exceeds your current token balance.`,
                            header: "Error occured",
                        });
                        return;
                    }
                    //withoutRound retuns a string, in order to get correct digit we need parse it through Number and then back to string
                    this.sendTokenAmount = Number(this.$root.core.withoutRound(this.sendTokenAmount)).toString();
                    this.disabled = true;
                    const approveRes = await this.$root.core.approve(this.blockchainA.CHAIN_ID, this.sendTokenAmount, this.currentAddress);
                    this.approvePending = true;
                    this.showTransactionModal = true;
                    if (!approveRes) {
                        this.approvePending = false;
                        this.showTransactionModal = false;

                        return;
                    }
                    if (approveRes == true) {
                        this.approvePending = false;
                    }

                    if (approveRes.wait) {
                        const approved = await approveRes.wait();
                        if (approved.transactionHash) {
                            this.approvePending = false;
                            this.approveSuccess = true;
                            this.transactionHash = approved.transactionHash;
                            this.$store.commit("push_notification", {
                                type: "successTransaction",
                                typeClass: "",
                                message: `Transaction has been confirmed.`,
                                header: "Success!",
                            });
                        }
                        setTimeout(() => (this.approveSuccess = false), 2000);
                    }
                    this.pendingTransaction = true;
                    this.showTransactionModal = true;

                    const rawResult = await this.$root.core.makeSwap(
                        this.blockchainA.CHAIN_ID,
                        this.currentAddress,
                        this.receiverAddress,
                        this.sendTokenAmount,
                        this.blockchainB.SYMBOL
                    );
                    if (!rawResult) {
                        this.pendingTransaction = false;
                        this.showTransactionModal = false;
                        return;
                    }

                    const result = await rawResult.wait();

                    if (result.transactionHash) {
                        this.disabled = false;
                        console.log("going to sign message", result.transactionHash);
                        await this.$root.core.signMessage(result.transactionHash);
                        console.log("signed successfully");

                        this.pendingTransaction = false;
                        this.successTransaction = true;
                        this.transactionHash = result.transactionHash;
                        this.$store.commit("push_notification", {
                            type: "successTransaction",
                            typeClass: "success",
                            message: `Transaction has been confirmed.`,
                            header: "Success!",
                        });
                        console.log("fetching event object");

                        // setTimeout(() => {
                        //     this.successTransaction = false;
                        // }, 000);
                        // await this.claim();
                    } else {
                        this.disabled = false;
                        this.pendingTransaction = false;
                        this.showTransactionModal = false;
                    }
                } catch (error) {
                    this.disabled = false;
                }
            },
            async claim() {
                if (this.transactionHash) {
                    try {
                        const messageObj = await this.$root.core.getClaimHash(this.transactionHash);
                        window.localStorage.setItem("claim", JSON.stringify(messageObj));
                        console.log("event obj received");

                        if (messageObj.to_chain !== this.chainSymbol) {
                            console.log("going to change network");
                            await this.changeNetwork(messageObj.to_chain);
                            await timer(3000);
                            const { userAddress: recipient, from_chain, message_hash, to_chain, event_obj } = messageObj;
                            this.disabled = true;
                            const rawRes = await this.$root.core.claimTokens(
                                event_obj.lockIdx,
                                recipient,
                                event_obj.amountHex,
                                from_chain,
                                message_hash,
                                to_chain
                            );
                            this.successTransaction = false;
                            this.pendingTransaction = true;
                            this.showTransactionModal = true;
                            const res = await rawRes.wait();

                            if (res.transactionHash) {
                                this.disabled = false;
                                this.pendingTransaction = false;
                                this.claimSuccess = true;
                                this.claimTnx = res.transactionHash;
                                this.selectedClaim = messageObj;
                                window.localStorage.removeItem("claim");
                                setTimeout(() => {
                                    this.claimSuccess = false;
                                    this.showTransactionModal = false;
                                    (this.claimTnx = ""), (this.selectedClaim = {}), this.$emit("closeTransModal");
                                }, 5000);
                                await this.$root.core.setClaimHash(this.transactionHash, res.transactionHash);
                            } else {
                                this.disabled = false;
                                this.pendingTransaction = false;
                                this.showTransactionModal = false;
                            }
                        } else {
                            const { userAddress: recipient, from_chain, message_hash, to_chain, event_obj } = messageObj;
                            this.disabled = true;
                            this.successTransaction = false;
                            this.pendingTransaction = true;
                            this.showTransactionModal = true;
                            const rawRes = await this.$root.core.claimTokens(
                                event_obj.lockIdx,
                                recipient,
                                event_obj.amountHex,
                                from_chain,
                                message_hash,
                                to_chain
                            );

                            const res = await rawRes.wait();

                            if (res.transactionHash) {
                                this.disabled = false;
                                this.pendingTransaction = false;
                                this.claimSuccess = true;
                                this.claimTnx = res.transactionHash;
                                this.selectedClaim = messageObj;
                                window.localStorage.removeItem("claim");
                                setTimeout(() => {
                                    this.claimSuccess = false;
                                    this.showTransactionModal = false;
                                    (this.claimTnx = ""), (this.selectedClaim = {}), this.$emit("closeTransModal");
                                }, 5000);
                                await this.$root.core.setClaimHash(this.transactionHash, res.transactionHash);
                            } else {
                                this.disabled = false;
                                this.claimSuccess = false;
                                this.showTransactionModal = false;
                                (this.claimTnx = ""), (this.selectedClaim = {}), this.$emit("closeTransModal");
                                this.pendingTransaction = false;
                            }
                        }
                    } catch (error) {
                        console.log(error);
                        this.disabled = false;
                        this.claimSuccess = false;
                        this.showTransactionModal = false;
                        (this.claimTnx = ""), (this.selectedClaim = {}), this.$emit("closeTransModal");
                        this.pendingTransaction = false;
                    }
                } else {
                    try {
                        console.log("sending claim request");
                        let messageObj = window.localStorage.getItem("claim");
                        if (messageObj && messageObj.length > 0) {
                            messageObj = JSON.parse(messageObj);

                            if (this.chainSymbol && messageObj.to_chain === this.chainSymbol) {
                                const { userAddress: recipient, from_chain, message_hash, to_chain, lock_hash, event_obj } = messageObj;
                                this.disabled = true;
                                const rawRes = await this.$root.core.claimTokens(
                                    event_obj.lockIdx,
                                    recipient,
                                    event_obj.amountHex,
                                    from_chain,
                                    message_hash,
                                    to_chain
                                );
                                const res = await rawRes.wait();
                                if (res.transactionHash) {
                                    this.disabled = false;
                                    window.localStorage.removeItem("claim");
                                    console.log("success");
                                    await this.$root.core.setClaimHash(lock_hash, res.transactionHash);
                                } else {
                                    this.disabled = false;
                                    this.claimSuccess = false;
                                    this.showTransactionModal = false;
                                    (this.claimTnx = ""), (this.selectedClaim = {}), this.$emit("closeTransModal");
                                    this.pendingTransaction = false;
                                }
                            } else {
                                if (!window.ethereum.chainId || !this.chainSymbol) return;
                                alert(`You have locks to claim in other chains, specifically: ${messageObj.to_chain}`);
                            }
                        }
                    } catch (error) {
                        this.disabled = false;
                        this.claimSuccess = false;
                        this.showTransactionModal = false;
                        (this.claimTnx = ""), (this.selectedClaim = {}), this.$emit("closeTransModal");
                        this.pendingTransaction = false;
                    }
                }
            },

            async changeNetwork(blockchain) {
                await this.$root.core.changeNetwork(this, blockchain);
            },
        },
        //     watch: {
        //         startAutoClaim: async function () {
        //             if (!this.startAutoClaim) return;
        //             await this.claim();
        //         },
        //     },
    };
</script>
