<template>
    <div :class="typeClass">        
        <div class="push_content">
            <i :class="{'i-danger': notification.typeClass == 'danger'
            , 'i-success': notification.typeClass == 'success'}"></i>
            <div class="push_title"> {{notification.header}}</div>
            <h4> {{notification.message}}</h4>
        </div>
        <button @click="closeNotif()" class="button_push_close">
            <i class="i-close"></i>
        </button>
        <!-- <button>   <div class="closeImg"></div></button> -->
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import MultiLang from '../../core/multilang.js'
export default {
    data() {
        return {
            timeout: null,
            lang: new MultiLang(this)
        }
    },
    props: ['notification'],
    computed: {
        typeClass() {
             return `block ${this.notification.typeClass} flexInlineBetween`
        }
    },

    created() {
        this.timeout = setTimeout(() => {
            this.remove_notification(this.notification);
        }, 4000);
    },
    methods: {
        ...mapMutations(['remove_notification']),
        closeNotif(){
            this.remove_notification(this.notification);
    }
    },

    beforeDestroy() {
        clearTimeout(this.timeout)
    },
    
}
</script>