<template>
    <div>
        <div v-if="showWalletOptions" class="modal">
            <div class="modal_wrapper">
                <div class="modal_header">
                    <div class="title">Choose wallet to connect</div>
                    <button @click="$emit('close')" class="button_modal_close">
                        <i class="i-close"></i>
                    </button>
                </div>
                <div class="modal_main">
                    <div class="modal_block">
                        <button @click="setupDesiredWallet('metamask'), $emit('setWalletAndAddress')" class="button button_connect-wallet">
                            <div class="choose-icon"></div>
                            Metamask
                        </button>
                        <button @click="setupDesiredWallet('walletconnect'), $emit('setWalletAndAddress')" class="button button_connect-wallet">
                            <div class="choose-icon-wl"></div>
                            Wallet Connect
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MultiLang from "../../core/multilang.js";
    import conf from "../../Config.json";
    var Config = conf[Number(window?.ethereum?.chainId) || conf.PRIMARY_BLOCKCHAIN.chainId];
    export default {
        props: ["showConnectWallet", "showNetworkSwitch", "blockchainA", "showWalletOptions", "noWallet"],
        data: function () {
            return {
                disableConnectButton: false,
                lang: new MultiLang(this),
            };
        },
        computed: {
            // disableButton() {
            //     if (this.blockchainA && this.blockchainA.chainId === "0x3") {
            //         return true;
            //     }
            // },
        },
        methods: {
            async connectWallet() {
                try {
                    this.disableConnectButton = true;
                    // Will open the MetaMask UI
                    // You should disable this button while the request is pending!
                    await window.ethereum.request({ method: "eth_requestAccounts" });
                    this.disableConnectButton = false;
                    this.$emit("close");
                    this.$store.commit("push_notification", {
                        type: "success",
                        typeClass: "success",
                        message: "Wallet has been connected",
                    });
                } catch (error) {
                    this.$store.commit("push_notification", {
                        type: "warning",
                        typeClass: "danger",
                        message:
                            error.message && error.message == "Already processing eth_requestAccounts. Please wait."
                                ? "Please proceed to your metamask extention to connect the wallet"
                                : "Something went wrong, please try again",
                    });
                    this.disableConnectButton = false;
                }
            },
            async setupDesiredWallet(walletOption) {
                const previousSelection = window.localStorage.getItem("selectedWallet");
                if (walletOption && previousSelection && walletOption.toLowerCase() === previousSelection.toLowerCase()) {
                    this.$emit("close");
                }
                if (walletOption) {
                    if (previousSelection === "walletconnect" && walletOption === "metamask") {
                        window.localStorage.setItem("selectedWallet", walletOption);
                        await this.$root.core.killSession();
                    }
                    window.localStorage.setItem("selectedWallet", walletOption);
                }
            },

            async changeNetwork(blockchain) {
                await this.$root.core.changeNetwork(this, blockchain);
            },
        },
    };
</script>
