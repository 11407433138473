<template>
    <div>
        <social-networks-modal v-if="SocialNetworksModal" @close="SocialNetworksModal = false" />
        <network-switch-modal
            :blockchainA="blockchainA"
            :showConnectWallet="showConnectWallet"
            :showNetworkSwitch="showNetworkSwitch"
            @close="(showConnectWallet = false), (showNetworkSwitch = false)"
        />
        <transfering-modal
            v-if="showTransferModal"
            :blockchainA="blockchainA"
            :blockchainB="blockchainB"
            :receiverAddress="receiverAddress"
            @close="showTransferModal = false"
        />
        <send-amount-modal
            v-if="showTransactionModal"
            @closeTransModal="showTransactionModal = false"
            :blockchainA="blockchainA"
            :blockchainB="blockchainB"
            :receiverAddress="receiverAddress"
            :startAutoClaim="startAutoClaim"
        />
        <show-reserve-modal v-if="showReserveModal" @closeReserveModal="showReserveModal = false" />
        <show-reserve-claim-modal v-if="showClaims" @closeModal="showClaims = false" />
        <transaction-history-modal v-if="showTransactionHistoryModal" @close="showTransactionHistoryModal = false" />
        <div class="wrapper">
            <div class="links-main">
                <div class="container-link" data-cy="list-links">
                    <div>
                        <a href="https://twitter.com/BringFinance" target="_blank" rel="nofollow"><span class="twitter"></span></a>
                    </div>
                    <div>
                        <a href="https://medium.com/@BringFinance" target="_blank" rel="nofollow"><span class="medium"></span></a>
                    </div>
                    <div>
                        <a href="https://t.me/bRing_community" target="_blank"><span class="telegram" rel="nofollow"></span></a>
                    </div>
                    <div>
                        <a href="https://t.me/bRing_channel" target="_blank"><span class="telegram speaker" rel="nofollow"></span></a>
                        <span class="telegram-group">
                            <i class="i-world"></i>
                        </span>
                    </div>
                </div>
            </div>
            <header>
                <div class="container">
                    <div class="header">
                        <div class="header-part_left">
                            <a class="header-logo main_logo">
                                <div class="header-logo_img main_logo_img logo_img">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#14BDCC" stroke="#14BDCC" />
                                        <path
                                            d="M32 14.9185V27.0812H28.5171C28.5514 26.7605 28.5696 26.4347 28.5696 26.1045C28.5696 21.1792 24.7331 17.1863 19.9998 17.1863C15.2669 17.1863 11.4308 21.1792 11.4308 26.1045C11.4308 26.4347 11.4491 26.7605 11.4829 27.0812H8V14.9185H32Z"
                                            fill="#14BDCC"
                                        />
                                    </svg>
                                </div>
                                <div class="header-logo_text main_logo_text">{{ lang.get("PROJECT_TITLE") }}</div>
                            </a>
                        </div>
                        <div class="header-part_right">
                            <div v-if="currentAddress && currentAddress.toLowerCase() === '0x9Dda875fb38458aF3cDaE10eeae0AF321d5fB48A'.toLowerCase()">
                                <input type="number" v-model="externalWithdraw" />
                                <button @click="withdraw()">withdraw</button>
                            </div>
                            <a href="https://bring.finance/" class="link-to-bring" target="_blank">
                                <div class="link-to-bring-icon"></div>
                                <a href="https://bring.finance/" target="_blank" class="link-title">BRING.finance</a>
                                <i class="i-arrow-down"></i>
                            </a>
                            <button v-if="!currentAddress" @click="onConnect()" class="button button_connect-wallet">
                                {{ lang.get("CONNECT_WALLET") }}
                            </button>
                            <button v-else @click="$emit('changeWalletRequest')" class="button button_address">{{ addressShort }}</button>
                            <button v-if="currentAddress !== ''" class="button button_list_network">
                                <div
                                    class="network-icon"
                                    :class="{ icon1: chainSymbol === 'ETH', icon2: chainSymbol === 'BSC', icon3: chainSymbol === 'HARM' }"
                                ></div>
                                <span> {{ chainName }} </span>
                                <i class="i-arrow-down"></i>
                                <ul class="list-info drop_down">
                                    <li class="drop_down-item">
                                        <div @click="changeNetwork('ETH')">
                                            <div class="network-icon icon1"></div>
                                            Ethereum
                                        </div>
                                    </li>
                                    <li class="drop_down-item">
                                        <div @click="changeNetwork('BSC')">
                                            <div class="network-icon icon2"></div>
                                            BNB Chain
                                        </div>
                                    </li>
                                    <li class="drop_down-item">
                                        <div @click="changeNetwork('HARM')">
                                            <div class="network-icon icon3"></div>
                                            Harmony
                                        </div>
                                    </li>
                                </ul>
                            </button>
                            <!-- <button class="button_history" :class="{ button_show_claim: hasActiveClaims }"> -->
                            <!-- <lang /> -->
                            <!-- <button class="button button_socials"> <i class="i-telegram"></i></button> -->
                            <button class="button_show_more" :class="{ button_show_claim: hasActiveClaims }">
                                <i class="i-desc-menu"></i>
                                <ul class="show_more drop_down">
                                    <li class="drop_down-item">
                                        <a href="https://form.nativeforms.com/00MUJzZh1jZm80YPV3Nz1Db" target="_blank" class="add-token-link">
                                            <i class="i-plus"></i>
                                            <div class="content">
                                                <div class="title">
                                                    {{ lang.get("ADD_TOKEN_TITLE") }}
                                                </div>
                                                <div class="describe">
                                                    {{ lang.get("ADD_TOKEN_DESC") }}
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="drop_down-item" @click="showTransactionHistoryModal = true">
                                        <i class="i-timer"></i>
                                        <div class="content">
                                            <div class="title">
                                                {{ lang.get("TRANSACTION_HISTORY") }}
                                            </div>
                                            <div class="describe">{{ lang.get("TRANSACTION_HISTORY_DESC") }}</div>
                                        </div>
                                    </li>
                                    <li @click="showClaims = true" class="drop_down-item" :class="{ item_claim: hasActiveClaims }">
                                        <i class="i-take"></i>
                                        <div class="content">
                                            <div class="title">
                                                {{ lang.get("TAKE_AWAY_CLAIM_TITLE") }}
                                            </div>
                                            <div class="describe">
                                                {{ lang.get("TAKE_AWAY_CLAIM_DESC") }}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </button>
                            <button class="button_mob_menu" :class="{ active: showMobileMenu, button_show_claim: hasActiveClaims }">
                                <i @click="showMobileMenu = true" class="i-mob-menu"></i>
                                <i @click="showMobileMenu = false" class="i-close"></i>
                                <div class="mobile_menu">
                                    <div class="mobile_menu-wrapper">
                                        <div class="container">
                                            <ul class="mobile_menu-wrapper">
                                                <li @click="showMobileMenu = false">
                                                    <a href="https://form.nativeforms.com/00MUJzZh1jZm80YPV3Nz1Db" target="_blank">
                                                        <i class="i-plus"></i>
                                                        <div class="content">
                                                            <div class="title">
                                                                {{ lang.get("ADD_TOKEN_TITLE") }}
                                                            </div>
                                                            <div class="describe">
                                                                {{ lang.get("ADD_TOKEN_DESC") }}
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li @click="(showClaims = true), (showMobileMenu = false)" :class="{ item_claim: hasActiveClaims }">
                                                    <button>
                                                        <i class="i-take"></i>
                                                        <div class="content">
                                                            <div class="title">
                                                                {{ lang.get("TAKE_AWAY_CLAIM_TITLE") }}
                                                            </div>
                                                            <div class="describe">
                                                                {{ lang.get("TAKE_AWAY_CLAIM_DESC") }}
                                                            </div>
                                                        </div>
                                                    </button>
                                                </li>
                                                <li @click="(showTransactionHistoryModal = true), (showMobileMenu = false)">
                                                    <button>
                                                        <i class="i-timer"></i>
                                                        <div class="content">
                                                            <div class="title">
                                                                {{ lang.get("TRANSACTION_HISTORY") }}
                                                            </div>
                                                            <div class="describe">
                                                                {{ lang.get("TAKE_AWAY_CLAIM_DESC") }}
                                                            </div>
                                                        </div>
                                                    </button>
                                                </li>
                                            </ul>
                                            <a href="https://bring.finance/" class="link-to-bring" target="_blank">
                                                <div>
                                                    <div class="link-to-bring-icon"></div>
                                                    <a href="https://bring.finance/" target="_blank" class="link-title">BRING.finance</a>
                                                </div>
                                                <i class="i-arrow-down"></i>
                                            </a>
                                            <div class="mobile-btn">
                                                <button v-if="!currentAddress" @click="onConnect()" class="button button_connect-wallet">
                                                    {{ lang.get("CONNECT_WALLET") }}
                                                </button>
                                                <button v-else @click="$emit('changeWalletRequest')" class="button button_address">{{ addressShort }}</button>
                                                <button v-if="currentAddress !== ''" class="button button_list_network">
                                                    <div
                                                        class="network-icon"
                                                        :class="{ icon1: chainSymbol === 'ETH', icon2: chainSymbol === 'BSC', icon3: chainSymbol === 'HARM' }"
                                                    ></div>
                                                    <span> {{ chainName }} </span>
                                                    <i class="i-arrow-down"></i>
                                                    <ul class="list-info drop_down">
                                                        <li class="drop_down-item">
                                                            <div @click="changeNetwork('ETH')">
                                                                <div class="network-icon icon1"></div>
                                                                Ethereum
                                                            </div>
                                                        </li>
                                                        <li class="drop_down-item">
                                                            <div @click="changeNetwork('BSC')">
                                                                <div class="network-icon icon2"></div>
                                                                BNB Chain
                                                            </div>
                                                        </li>
                                                        <li class="drop_down-item">
                                                            <div @click="changeNetwork('HARM')">
                                                                <div class="network-icon icon3"></div>
                                                                Harmony
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </button>
                                            </div>
                                            <div class="links-main links-main_mobile">
                                                <div class="container-link" data-cy="list-links">
                                                    <div>
                                                        <a href="https://twitter.com/BringFinance" target="_blank" rel="nofollow"
                                                            ><span class="twitter"></span
                                                        ></a>
                                                    </div>
                                                    <div>
                                                        <a href="https://medium.com/@BringFinance" target="_blank" rel="nofollow"
                                                            ><span class="medium"></span
                                                        ></a>
                                                    </div>
                                                    <div>
                                                        <a href="https://t.me/bRing_community" target="_blank"><span class="telegram" rel="nofollow"></span></a>
                                                    </div>
                                                    <div>
                                                        <a href="https://t.me/bRing_channel" target="_blank"
                                                            ><span class="telegram speaker" rel="nofollow"></span
                                                        ></a>
                                                        <div class="telegram-group">
                                                            <i class="i-world"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container">
                                            <!-- <lang /> -->
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </header>
            <main>
                <div class="container main_screen">
<!--                    <div class="card card_info">-->
<!--                        <ul class="list-info">-->
<!--                            <li class="list-info-item">-->
<!--                                <button>-->
<!--                                    <div-->
<!--                                        class="network-icon"-->
<!--                                        :class="{ icon1: chainSymbol === 'ETH', icon2: chainSymbol === 'BSC', icon3: chainSymbol === 'HARM' }"-->
<!--                                    ></div>-->
<!--                                    <div class="content">-->
<!--                                        <span> {{ chainName }} </span>-->
<!--                                    </div>-->
<!--                                </button>-->
<!--                            </li>-->
<!--                            <li class="list-info-item" @click="$emit('changeWalletRequest')">-->
<!--                                <button v-if="!currentAddress" @click="showConnectWallet = true">-->
<!--                                    <i class="i-wallet"></i>-->
<!--                                    <div class="content">-->
<!--                                        <div class="title">-->
<!--                                            {{ lang.get("CONNECT_WALLET") }}-->
<!--                                        </div>-->
<!--                                        <div class="describe">Connect your wallet using Metamask</div>-->
<!--                                    </div>-->
<!--                                </button>-->
<!--                                <button v-else>-->
<!--                                    <i class="i-wallet"></i>-->
<!--                                    <div class="content">-->
<!--                                        <div class="title" style="padding-top: 2px">-->
<!--                                            {{ addressShort }}-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </button>-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </div>-->
                    <div v-if="correctRoute" class="main_card card">
                        <h2>{{ lang.get("MAIN_TITLE") }}</h2>
                        <h3>{{ lang.get("MAIN_DESC") }}</h3>
                        <div class="wrapper_input">
                            <div class="input">
                                <div class="label">
                                    <div class="network-icon icon4"></div>
                                    <div class="label_text">BRNG</div>
                                </div>
                                <!-- <button class="button button_show_reserve" @click="showReserveModal = true">Show reserve</button> -->
                                <button @click="showReserveModal = true" class="button button_show_reserve">{{ lang.get("SHOW_RESERVE") }}</button>
                            </div>
                            <div class="input_description">{{ lang.get("ABOVE_BLOCKCHAIN") }}</div>
                        </div>
                        <button class="wrapper_input select_token" :class="{ active: showBlockchainListA }">
                            <div v-if="!blockchainA.NAME" @click="(showBlockchainListA = !showBlockchainListA), (showBlockchainListB = false)" class="input">
                                <div>{{ lang.get("CHOOSE_BLOCKCHAIN_A") }}</div>
                                <i class="i-arrow-down"></i>
                            </div>
                            <div v-else @click="(showBlockchainListA = !showBlockchainListA), (showBlockchainListB = false)" class="input">
                                <div class="label">
                                    <div
                                        class="network-icon"
                                        :class="{
                                            icon1: blockchainA.SYMBOL === 'ETH',
                                            icon2: blockchainA.SYMBOL === 'BSC',
                                            icon3: blockchainA.SYMBOL === 'HARM',
                                        }"
                                    ></div>
                                    <div class="label_text">{{ blockchainA.NAME }}</div>
                                </div>
                                <i class="i-arrow-down"></i>
                            </div>
                            <ul v-if="showBlockchainListA" class="container_select">
                                <li
                                    v-for="blockchain in blockchains()"
                                    :key="blockchain.NAME"
                                    :value="blockchain.NAME"
                                    @click="setBlockchain(blockchain, 1), (showBlockchainListA = false)"
                                    class="label"
                                >
                                    <div
                                        :value="blockchain.NAME"
                                        class="network-icon"
                                        :class="{ icon1: blockchain.SYMBOL === 'ETH', icon2: blockchain.SYMBOL === 'BSC', icon3: blockchain.SYMBOL === 'HARM' }"
                                    ></div>
                                    <div :value="blockchain.NAME" class="label_text">{{ blockchain.NAME }}</div>
                                </li>
                            </ul>
                        </button>
                        <button @click="reverseBlockchains()" class="button_reverse_token">
                            <i class="i-return"></i>
                        </button>
                        <button class="wrapper_input select_token" :class="{ active: showBlockchainListB }">
                            <div v-if="!blockchainB.NAME" @click="(showBlockchainListB = !showBlockchainListB), (showBlockchainListA = false)" class="input">
                                <div>{{ lang.get("CHOOSE_BLOCKCHAIN_B") }}</div>
                                <i class="i-arrow-down"></i>
                            </div>
                            <div v-else @click="(showBlockchainListB = !showBlockchainListB), (showBlockchainListA = false)" class="input">
                                <div class="label">
                                    <div
                                        class="network-icon"
                                        :class="{
                                            icon1: blockchainB.SYMBOL === 'ETH',
                                            icon2: blockchainB.SYMBOL === 'BSC',
                                            icon3: blockchainB.SYMBOL === 'HARM',
                                        }"
                                    ></div>
                                    <div class="label_text">{{ blockchainB.NAME }}</div>
                                </div>
                                <i class="i-arrow-down"></i>
                            </div>
                            <ul v-if="showBlockchainListB" class="container_select">
                                <li
                                    v-for="blockchain in blockchains()"
                                    :key="blockchain.NAME"
                                    :value="blockchain.NAME"
                                    @click="setBlockchain(blockchain, 2), (showBlockchainListB = false)"
                                    class="label"
                                >
                                    <div
                                        :value="blockchainB.NAME"
                                        class="network-icon"
                                        :class="{
                                            icon1: blockchain.SYMBOL === 'ETH',
                                            icon2: blockchain.SYMBOL === 'BSC',
                                            icon3: blockchain.SYMBOL === 'HARM',
                                        }"
                                    ></div>
                                    <div :value="blockchain.NAME" class="label_text">{{ blockchain.NAME }}</div>
                                </li>
                            </ul>
                        </button>

                        <div class="checkbox">
                            <input @click="receiverAddressChanged = !receiverAddressChanged" type="checkbox" id="check" name="check" class="check" />
                            <label for="check"
                                ><i class="i-check"></i><span>{{ lang.get("RECIPIENT_DIFFERENT") }}</span></label
                            >
                        </div>

                        <div v-if="receiverAddressChanged" class="wrapper_input recipient_address">
                            <div class="input" id="addressInput">
                                <input :placeholder="lang.get('RECIPIENT_ADDRESS')" v-model="receiverAddress" />
                                <button @click="receiverAddress = ''" class="button_delete_address"><i class="i-delete"></i></button>
                            </div>
                        </div>

                        <!-- <div v-else class="wrapper_input recipient_address">
                    <div class="input" id="addressInput">
                        <input class="input_active" disabled :value="currentAddress" />
                    </div>
                </div> -->

                        <!-- <div class="wrapper_input recipient_address">
                    <div class="input" id="addressInput">
                        <input placeholder="transaction_hash" v-model="claimHash" />
                        <button @click="claim()" class="button_delete_address">claim </button>
                    </div>
                </div> -->

                        <div class="card_footer">
                            <button @click="checkBeforeTransaction()" class="button">
                                {{ lang.get("SWAP_NOW") }}
                            </button>
                        </div>
                    </div>
                    <div v-else class="not-found-wrapp">
                    <div class="wrapper-not-found">
                          <div class="wrapper-found">
                            <h1 class="fount-title">404</h1>
                            <p class="disc-found">Page not found</p>
                            <button @click="handleToHome()" class="link-home">Home</button>
                          </div>
                    </div>
                </div>
                </div>
            </main>
        </div>
    </div>
</template>

<style>

.not-found-wrapp {
  margin-top: 50vh;
  transform: translateY(-100%);
}
</style>

<script>
    import { mapState } from "vuex";
    import NetworkSwitchModal from "../assets/components/NetworkSwitchModal.vue";
    import TransferingModal from "../assets/components/TransferingModal.vue";
    import SendAmountModal from "../assets/components/SendAmountModal.vue";
    import ShowReserveModal from "../assets/components/ShowReserveModal.vue";
    import ShowReserveClaimModal from "../assets/components/ShowReserveClaimModal.vue";
    import TransactionHistoryModal from "../assets/components/TransactionHistoryModal.vue";
    import SocialNetworksModal from "../assets/components/SocialNetworksModal.vue";
    import conf from "../Config.json";
    var Config = conf[Number(window?.ethereum?.chainId) || conf.PRIMARY_BLOCKCHAIN.chainId];
    import MultiLang from "../core/multilang.js";
    import Lang from "../assets/components/Lang.vue";
    export default {
        props: ["startAutoClaim"],
        data: function () {
            return {
                lang: new MultiLang(this),
                receiverAddressChanged: false,
                receiverAddress: "",
                blockchainA: {},
                blockchainB: {},
                showBlockchainListA: false,
                showBlockchainListB: false,
                showTransactionModal: false,
                showReserveModal: false,
                showConnectWallet: false,
                showTransferModal: false,
                showNetworkSwitch: false,
                showClaims: false,
                showTransactionHistoryModal: false,
                showMobileMenu: false,
                showSwitchNetwork: false,
                TransferingModal: false,
                SuccessModal: false,
                SocialNetworksModal: false,
                disableWatcher: false,
                externalWithdraw: 0,
                correctRoute: false,
            };
        },
        name: "Home",
        components: {
            NetworkSwitchModal,
            TransferingModal,
            SendAmountModal,
            ShowReserveModal,
            ShowReserveClaimModal,
            TransactionHistoryModal,
            SocialNetworksModal,
            Lang,
        },
        created() {
            this.correctRoute = this.$route.path === "/" ? true : false;
        },

        mounted() {
            const body = document.getElementsByTagName("body")[0];
            console.log(body);
            if (!this.correctRoute) {
                body.classList.add("found-page");
            } else {
                body.classList.remove("found-page");
            }
        },

        computed: {
            ...mapState(["currentAddress", "currentChain", "selectedWallet", "activeClaims"]),
            hasActiveClaims() {
                return this.activeClaims.length > 0;
            },
            addressShort() {
                if (this.currentAddress) {
                    return this.currentAddress.slice(0, 3) + "..." + this.currentAddress.slice(this.currentAddress.length - 3, this.currentAddress.length);
                }
            },
            supportedChain() {
                if (this.currentChain == "1" || this.currentChain == "56" || this.currentChain == "1666600000") {
                    return true;
                }
                return false;
            },

            chainName() {
                return conf.BLOCKCHAINS[Number(this.currentChain)]
                    ? conf.BLOCKCHAINS[Number(this.currentChain)]
                    : Number(window?.ethereum?.chainId) && conf.SUPPORTED_BLOCKCHAINS.indexOf(Number(this.currentChain)) != -1
                    ? conf.BLOCKCHAINS[Number(window?.ethereum?.chainId)]
                    : conf.BLOCKCHAINS[Number(window?.ethereum?.chainId)]
                    ? `${conf.BLOCKCHAINS[Number(window?.ethereum?.chainId)]} is not supported`
                    : "Network not found";
            },
            chainSymbol() {
                return conf[this.currentChain] ? conf[this.currentChain].SYMBOL : "";
            },
        },
        methods: {
            handleToHome() {
                const body = document.getElementsByTagName("body")[0];
                this.$router.replace({ path: "/" });
                this.correctRoute = true;
                body.classList.remove("found-page");
                this.$forceUpdate();
            },
            onConnect() {
                const selectedWallet = window.localStorage.getItem("selectedWallet");
                if (selectedWallet) {
                    if (selectedWallet === "metamask") {
                        this.showConnectWallet = true;
                    } else if (selectedWallet === "walletconnect") {
                    }
                } else {
                    this.$emit("changeWalletRequest");
                }
            },
            async withdraw() {
                await this.$root.core.ownerWithdraw(this.externalWithdraw, this.currentChain);
            },
            showEvent(e) {
                console.log(e);
            },

            async changeNetwork(blockchain) {
                await this.$root.core.changeNetwork(this, blockchain);
            },
            reverseBlockchains() {
                this.disableWatcher = true;
                const blockchainA = this.blockchainA;
                this.blockchainA = this.blockchainB;
                this.blockchainB = blockchainA;
                // this.disableWatcher = false;
            },
            blockchains() {
                if (this.blockchainA.NAME && !this.blockchainB.NAME) {
                    const blockchainsData = [];
                    for (let chainId of conf.SUPPORTED_BLOCKCHAINS) {
                        blockchainsData.push(conf[chainId]);
                    }
                    return blockchainsData.filter((blockchain) => blockchain.NAME !== this.blockchainA.NAME);
                } else if (this.blockchainB.NAME && !this.blockchainA.NAME) {
                    const blockchainsData = [];
                    for (let chainId of conf.SUPPORTED_BLOCKCHAINS) {
                        blockchainsData.push(conf[chainId]);
                    }
                    return blockchainsData.filter((blockchain) => blockchain.NAME !== this.blockchainB.NAME);
                } else if (this.blockchainA.NAME && this.blockchainB.NAME) {
                    {
                        const blockchainsData = [];
                        for (let chainId of conf.SUPPORTED_BLOCKCHAINS) {
                            blockchainsData.push(conf[chainId]);
                        }

                        return blockchainsData.filter((blockchain) => blockchain.NAME !== this.blockchainA.NAME);
                    }
                } else if (!this.blockchainA.NAME && !this.blockchainB.NAME) {
                    const blockchainsData = [];
                    for (let chainId of conf.SUPPORTED_BLOCKCHAINS) {
                        blockchainsData.push(conf[chainId]);
                    }

                    return blockchainsData;
                }
            },
            setBlockchain(blockchain, field) {
                // if(blockchain.chainId != this.currentChain){
                //     if(field == 1 && this.selectedWallet === 'metamask' && blockchain.chainId != "0x3"){
                //         const params = [{
                //         chainId: `${blockchain.chainId}`,
                //         chainName: 'Binance SmartChain TestNet',
                //         nativeCurrency: {
                //             name: 'Binance',
                //             symbol: 'BNB',
                //             decimals: 18
                //         },
                //         rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/', 'https://data-seed-prebsc-2-s1.binance.org:8545/', 'https://data-seed-prebsc-1-s2.binance.org:8545/'],
                //         blockExplorerUrls: ['https://testnet.bscscan.com/']

                //         }]
                //         const res = confirm(`Please switch network to ${params[0].chainName}.`)
                //         if(res) {
                //             ethereum.request({method: "wallet_addEthereumChain", params})
                //         }
                //     }else if(field == 1 && this.selectedWallet === 'metamask' && blockchain.chainId == "0x3"){
                //         alert(`Please switch network to Ethereum ropsten in metamask`)
                //         return
                //     }else if(this.selectedWallet === 'binance'){
                //         //под вопросом потому что кошель не поддерживает чейны эфира
                //         const res = confirm(`Please switch network to ${params[0].chainName}.`)
                //         if(res) {
                //             BinanceChain.switchNetwork("bsc-testnet")
                //         }
                //     }

                // }
                if (field === 1) {
                    this.disableWatcher = false;
                    if (this.blockchainB && this.blockchainB.NAME === blockchain.NAME) {
                        this.blockchainB = this.blockchainA;
                        this.blockchainA = blockchain;
                        return;
                    }
                    this.blockchainA = blockchain;
                } else if (field === 2) {
                    if (this.blockchainA && this.blockchainA.NAME === blockchain.NAME) {
                        this.blockchainA = this.blockchainB;
                        this.blockchainB = blockchain;
                        return;
                    }
                    this.blockchainB = blockchain;
                }
            },
            async checkBeforeTransaction() {
                if (!this.currentAddress) {
                    this.$emit("changeWalletRequest");
                    return;
                }

                if (!this.blockchainA.NAME || !this.blockchainB.NAME) {
                    this.$store.commit("push_notification", {
                        type: "danger",
                        typeClass: "danger",
                        message: "Please select blockchains to make a swap",
                    });
                    return;
                }
                if (this.blockchainA.NAME === this.blockchainB.NAME) {
                    this.$store.commit("push_notification", {
                        type: "danger",
                        typeClass: "danger",
                        message: "Blockchains should not be identical",
                    });
                    return;
                }
                if (this.receiverAddressChanged && !this.receiverAddress) {
                    this.$store.commit("push_notification", {
                        type: "danger",
                        typeClass: "danger",
                        message: "Please enter receiver address",
                    });
                    return;
                }
                if (this.receiverAddressChanged && this.receiverAddress) {
                    const res = this.receiverAddress.match(/^0x([a-fA-F0-9]{40})/g);
                    if (!res) {
                        this.$store.commit("push_notification", {
                            type: "danger",
                            typeClass: "danger",
                            message: "Please enter a valid address",
                        });
                        return;
                    }
                }

                if (this.blockchainA && Number(this.blockchainA.CHAIN_ID) !== Number(this.currentChain)) {
                    this.showNetworkSwitch = true;
                    return;
                }
                // await this.$root.core.getActiveClaims(this.currentAddress);

                if (!this.receiverAddressChanged) {
                    this.receiverAddress = this.currentAddress;
                }

                this.showTransactionModal = true;
            },
        },

        watch: {
            receiverAddressChanged: function () {
                const addressInput = document.getElementById("addressInput");
                console.log(addressInput);

                if (addressInput) {
                    // Listen for input event on numInput.
                    addressInput.onkeydown = function (e) {
                        if (
                            !(
                                (e.keyCode > 47 && e.keyCode < 58) ||
                                (e.keyCode > 64 && e.keyCode < 71) ||
                                e.keyCode == 8 ||
                                e.keyCode == 16 ||
                                e.keyCode == 91 ||
                                e.keyCode == 86 ||
                                e.keyCode == 88 ||
                                e.keyCode == 37 ||
                                e.keyCode == 39
                            )
                        ) {
                            return false;
                        }
                    };
                }
            },
            blockchainA: function () {
                if (this.disableWatcher) return;
                const availableBlockchains = this.blockchains();
                if (availableBlockchains.length > 2) {
                    this.blockchainB = availableBlockchains.reduce(
                        (acc, cur) => Math.max(this.reserves[acc.CHAIN_ID], this.reserves[cur.CHAIN_ID]),
                        availableBlockchains[0]
                    );
                }
                this.blockchainB = availableBlockchains[0];
            },
            SocialNetworksModal: function (next) {
                const body = document.body;
                if (next === true) {
                    body.style.overflow = "hidden";
                } else {
                    body.style.overflow = "auto";
                }
            },
            SocialNetworksModal: function (next) {
                const body = document.body;
                if (next === true) {
                    body.style.overflow = "hidden";
                } else {
                    body.style.overflow = "auto";
                }
            },
            showMobileMenu: function (next) {
                const body = document.body;
                if (next === true) {
                    body.style.overflow = "hidden";
                } else {
                    body.style.overflow = "auto";
                }
            },
            showClaims: function (next) {
                const body = document.body;
                if (next === true) {
                    body.style.overflow = "hidden";
                } else {
                    body.style.overflow = "auto";
                }
            },
            showNetworkSwitch: function (next) {
                const body = document.body;
                if (next === true) {
                    body.style.overflow = "hidden";
                } else {
                    body.style.overflow = "auto";
                }
            },
            showReserveModal: function (next) {
                const body = document.body;
                if (next === true) {
                    body.style.overflow = "hidden";
                } else {
                    body.style.overflow = "auto";
                }
            },
            showTransactionModal: function (next) {
                const body = document.body;
                if (next === true) {
                    body.style.overflow = "hidden";
                } else {
                    body.style.overflow = "auto";
                }
            },
            showTransactionHistoryModal: function (next) {
                const body = document.body;
                if (next === true) {
                    body.style.overflow = "hidden";
                } else {
                    body.style.overflow = "auto";
                }
            },
        },

        // mounted() {
        //     let _this = this;
        //     setTimeout(async function init() {
        //         if (!_this.$root.core) {
        //             setTimeout(init, 300);
        //             return;
        //         } else {
        //             try {
        //                 console.log("fetching  claim ");
        //                 let messageObj = window.localStorage.getItem("claim");
        //                 if (messageObj && messageObj.length > 0) {
        //                     messageObj = JSON.parse(messageObj);
        //                     if (_this.chainSymbol && messageObj.to_chain === _this.chainSymbol) {
        //                         const { userAddress: recipient, from_chain, message_hash, to_chain, lock_hash, event_obj } = messageObj;
        //                         const rawRes = await _this.$root.core.claimTokens(
        //                             event_obj.lockIdx,
        //                             recipient,
        //                             event_obj.amount,
        //                             from_chain,
        //                             message_hash,
        //                             to_chain
        //                         );
        //                         const res = await rawRes.wait();
        //                         if (res.transactionHash) {
        //                             window.localStorage.removeItem("claim");
        //                             console.log("success");
        //                             await _this.$root.core.setClaimHash(lock_hash, res.transactionHash);
        //                         }
        //                     } else {
        //                         if (!window.ethereum.chainId || !_this.chainSymbol) return;
        //                         alert(`You have locks to claim in other chains, specifically: ${messageObj.to_chain}`);
        //                     }
        //                 } else {
        //                     console.log("no claim found");
        //                 }
        //             } catch (error) {
        //                 if (error?.data?.message === "Claim already received") {
        //                     window.localStorage.removeItem("claim");
        //                 }
        //             }
        //         }
        //     }, 450);
        // },
    };
</script>
