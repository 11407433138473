import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        currentAddress: "",
        notifications: [],
        currentChain: "",
        selectedWallet: "",
        activeClaims: [],
        transactionsHistory: [],
        reserves: {},
        currentBlockchain: null,
        lang: "en",
    },
    mutations: {
        setCurrentAddress(state, data) {
            state.currentAddress = data;
        },
        setCurrentBlockchain(state, data) {
            state.currentBlockchain = data;
        },
        push_notification(state, notification) {
            state.notifications.push({
                ...notification,
                id: (Math.random().toString(36) + Date.now().toString(36)).substr(2),
            });
        },
        remove_notification(state, notificationToRemove) {
            state.notifications = state.notifications.filter((notification) => notification.id != notificationToRemove.id);
        },
        setChainId(state, chainId) {
            state.currentChain = chainId;
        },
        setActiveClaims(state, claims) {
            state.activeClaims = claims;
        },
        setTransactionsHistory(state, transactions) {
            state.transactionsHistory = transactions;
        },
        setReserves(state, reserves) {
            state.reserves = reserves;
        },
        setLanguage(state, lang) {
            state.lang = lang;
        },
    },
    actions: {
        updateLanguage({ commit }, lang) {
            commit("setLanguage", lang);
            localStorage.setItem("lang", lang);
        },
    },
    modules: {},
    getters: {
        getLanguage(state) {
            return state.lang;
        },
    },
});
