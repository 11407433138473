<template>
    <div>
        <div class="modal">
            <div class="modal_wrapper modal_wrapper__table">
                <div class="modal_header">
                    <div class="title">{{ lang.get("TAKE_AWAY_CLAIM_TITLE") }}</div>
                    <button @click="$emit('closeModal')" class="button_modal_close">
                        <i class="i-close"></i>
                    </button>
                </div>
                <div class="modal_main">
                    <div class="wrapper_input recipient_address">
                        <div class="input" id="addressInput">
                            <input placeholder="Type your transaction hash here" v-model="lockHash" @input="selectedClaim = {}" />
                            <!-- <button @click="claim()" class="button_delete_address">claim </button> -->
                        </div>
                    </div>
                    <div class="modal_select">
                        <div class="table_container table_container-select">
                            <table v-if="activeClaims.length > 0 && !claimAlreadyReceived && !lockNotFound" class="select_to_claim">
                                <thead>
                                    <td>{{ lang.get("NETWORK") }}</td>
                                    <td>{{ lang.get("ASSET") }}</td>
                                    <td>{{ lang.get("AMOUNT") }}</td>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="claim in currentClaims"
                                        :key="claim.tnxHash"
                                        :class="{ active: claimSelected(claim) }"
                                        @click="selectedClaim = claim"
                                    >
                                        <td class="td_conversion">
                                            <span class="name">{{ claim.from_chain }}</span
                                            ><span>→</span><span class="name">{{ claim.to_chain }}</span>
                                        </td>
                                        <td>
                                            <span> BRNG </span>
                                        </td>
                                        <td>{{ claim.event_obj.amount }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div v-else-if="!claimAlreadyReceived && !lockNotFound" class="info_empty">
                                {{ lang.get("EMPTY_LIST") }}
                            </div>
                            <div v-else-if="(activeClaims.length > 0 || activeClaims.length === 0) && claimAlreadyReceived" class="info_empty">
                                You have no tokens to claim
                            </div>
                            <div v-else-if="(activeClaims.length > 0 || activeClaims.length === 0) && lockNotFound" class="info_empty">
                                We can't find your transaction. It may have been conducted on a different network. Check your current network
                            </div>
                        </div>
                    </div>

                    <div v-if="selectedClaim.to_chain" class="container_half_buttons two_buttons">
                        <button v-if="claimWithdrawPending" @click="requestClaim()" class="button button_claim" disabled="disabled">
                            <div class="loading"></div>
                        </button>
                        <button
                            v-else-if="chainSymbol(currentChain) !== selectedClaim.to_chain"
                            @click="changeNetwork(selectedClaim.to_chain)"
                            class="button button_claim button_claim-border"
                        >
                            {{ lang.get("SWITCH_TO") }} {{ selectedClaim.to_chain }}
                        </button>
                        <button v-else-if="chainSymbol(currentChain) === selectedClaim.to_chain" @click="requestClaim()" class="button button_claim">
                            {{ lang.get("CLAIM") }}
                        </button>
                    </div>
                    <div
                        v-else-if="this.lockHash.length === 66"
                        class="container_half_buttons"
                        :class="{ two_buttons: claimAlreadyReceived === false && lockNotFound === false }"
                    >
                        <button v-if="claimWithdrawPending" @click="claimByTxn()" class="button button_claim" disabled="disabled ">
                            <div class="loading"></div>
                        </button>
                        <button v-else @click="claimByTxn()" class="button button_claim" :disabled="claimAlreadyReceived === true || lockNotFound === true">
                            {{ lang.get("CLAIM") }}
                        </button>
                    </div>

                    <div v-else class="container_half_buttons">
                        <button
                            v-if="!claimWithdrawPending"
                            @click="requestClaim()"
                            class="button button_claim"
                            :disabled="!selectedClaim.lock_hash || activeClaims.length == 0"
                        >
                            {{ lang.get("CLAIM") }}
                        </button>
                        <button v-else @click="requestClaim()" class="button button_claim" disabled="disabled"><div class="loading"></div></button>
                    </div>
                </div>
            </div>
        </div>
        <transfering-modal
            v-if="claimSuccess"
            :claimTnx="claimTnx"
            :claimSuccess="claimSuccess"
            :selectedClaim="selectedClaim"
            @closeClaimNotif="closeClaimNotif()"
        />
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import TransferingModal from "../components/TransferingModal.vue";
    import MultiLang from "../../core/multilang.js";
    import conf from "../../Config.json";

    const timer = (ms) => {
        console.log("delay");
        return new Promise((res) => setTimeout(res, ms));
    };
    export default {
        name: "ShowReserveClaimModal",
        data: function () {
            return {
                selectedClaim: {},
                lang: new MultiLang(this),
                claimWithdrawPending: false,
                claimSuccess: false,
                claimTnx: "",
                lockHash: "",
                currentClaims: [],
                claimAlreadyReceived: false,
                lockNotFound: false,
            };
        },
        components: { TransferingModal },
        computed: {
            ...mapState(["activeClaims", "selectedWallet", "currentChain", "reserves", "transactionsHistory"]),
            switchTo() {
                return this.currentChain === 1 ? "Ethereum" : this.currentChain === 56 ? "BNB Chain" : "Harmony";
            },
        },
        methods: {
            async claimByTxn() {
                const messageObj = await this.$root.core.getClaimHash(this.lockHash);
                window.localStorage.setItem("claim", JSON.stringify(messageObj));

                if (messageObj.to_chain !== this.chainSymbol(this.currentChain)) {
                    try {
                        await this.changeNetwork(messageObj.to_chain);
                        await timer(1500);
                        const { userAddress: recipient, from_chain, message_hash, to_chain, event_obj, lock_hash } = messageObj;

                        const rawRes = await this.$root.core.claimTokens(event_obj.lockIdx, recipient, event_obj.amountHex, from_chain, message_hash, to_chain);

                        const res = await rawRes.wait();
                        if (res.transactionHash) {
                            window.localStorage.removeItem("claim");
                            await this.$root.core.setClaimHash(lock_hash, res.transactionHash);
                        }
                    } catch (error) {
                        window.localStorage.removeItem("claim");
                    }
                } else {
                    try {
                        const { userAddress: recipient, from_chain, message_hash, to_chain, event_obj, lock_hash } = messageObj;

                        const rawRes = await this.$root.core.claimTokens(event_obj.lockIdx, recipient, event_obj.amountHex, from_chain, message_hash, to_chain);
                        const res = await rawRes.wait();
                        if (res.transactionHash) {
                            this.claimSuccess = true;
                            window.localStorage.removeItem("claim");
                            await this.$root.core.setClaimHash(lock_hash, res.transactionHash);
                        }
                    } catch (error) {
                        window.localStorage.removeItem("claim");
                    }
                }
            },
            chainSymbol(chainId) {
                return conf[chainId].SYMBOL;
            },
            claimSelected(event) {
                return event.lock_hash === this.selectedClaim.lock_hash;
            },
            async requestClaim() {
                if (this.lockHash.length === 66 && !this.selectedClaim) {
                    await this.claim();
                    return;
                }
                const reserves = this.currentChain == 56 ? this.reserves[56] : this.reserves[166660000];

                if (Number(this.selectedClaim.event_obj.amount) > Number(reserves)) {
                    this.$store.commit("push_notification", {
                        type: "",
                        typeClass: "danger",
                        message: `The amount of locked tokens on this contract is less than your claim`,
                        header: "Error, try again later",
                    });
                    return;
                }
                this.claimWithdrawPending = true;

                const res = await this.$root.core.claimTokens(
                    this.selectedClaim.event_obj.lockIdx,
                    this.selectedClaim.event_obj.recipient,
                    this.selectedClaim.event_obj.amountHex,
                    this.selectedClaim.from_chain,
                    this.selectedClaim.message_hash,
                    this.selectedClaim.to_chain
                );
                if (!res) {
                    this.claimWithdrawPending = false;
                    this.$store.commit("push_notification", {
                        type: "",
                        typeClass: "danger",
                        message: `Transaction was not made`,
                        header: "Error, try again later or contract support",
                    });
                    return;
                }

                const result = await res.wait();

                if (result.transactionHash) {
                    await this.$root.core.setClaimHash(this.selectedClaim.lock_hash, result.transactionHash);
                    this.claimSuccess = true;
                    this.claimTnx = result.transactionHash;
                    this.$store.commit("push_notification", {
                        type: "successTransaction",
                        typeClass: "success",
                        message: `Transaction has been confirmed.`,
                        header: "Success!",
                    });
                }
                this.claimWithdrawPending = false;
            },
            closeClaimNotif() {
                this.selectedClaim = {};
                this.claimSuccess = false;
                this.claimFailure = false;
                this.claimTnx = "";
            },
            async changeNetwork(to_chain = this.selectedClaim.to_chain) {
                await this.$root.core.changeNetwork(this, to_chain);
            },
        },
        mounted() {
            if (this.activeClaims.length > 0) {
                this.selectedClaim = this.activeClaims[0];
                this.currentClaims = this.activeClaims;
            }
        },
        watch: {
            lockHash: function (newVal) {
                this.claimAlreadyReceived = false;
                this.lockNotFound = false;
                if (newVal === "") {
                    this.currentClaims = this.activeClaims;
                    this.selectedClaim = this.activeClaims[0];
                    return;
                }
                this.currentClaims = this.activeClaims.filter((el) => el.lock_hash.toLowerCase() === this.lockHash.toLowerCase());
                const lockAndClaimObj = this.transactionsHistory.filter((el) => el.lock_hash.toLowerCase() === newVal.toLowerCase());
                if (lockAndClaimObj.length) {
                    if (lockAndClaimObj[0].claim_hash) {
                        this.claimAlreadyReceived = true;
                    }
                } else {
                    this.lockNotFound = true;
                }
            },
            activeClaims: function (newValue, oldValue) {
                if (newValue.length !== oldValue.length) {
                    this.currentClaims = newValue;
                }
            },
        },
    };
</script>
