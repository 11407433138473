<template>
    <div class="modal">
        <div v-if="approvePending" class="modal_wrapper">
            <div class="modal_header">
                <div class="title">{{ lang.get("APPROVE_SWAP") }}</div>
            </div>
            <div class="modal_main">
                <div class="modal_block">
                    <div class="modal_main_icon">
                        <div class="icon_loader"></div>
                    </div>
                    <h4>{{ lang.get("APPRIVE_SWAP_MESSAGE") }}</h4>
                    <h4 class="hint">
                        {{ lang.get("DO_NOT_CLOSE") }}
                    </h4>
                </div>
            </div>
        </div>
        <div v-else-if="pendingTransaction" class="modal_wrapper">
            <div class="modal_header">
                <div class="title">{{ lang.get("SENDING_SWAP") }}</div>
                <!-- <button @click="$emit('close')" class="button_modal_close">
                        <i class="i-close"></i>
                    </button> -->
            </div>
            <div class="modal_main">
                <div class="modal_block">
                    <div class="modal_main_icon">
                        <div class="icon_loader"></div>
                    </div>
                    <h4>{{ lang.get("SENDING_SWAP_MESSAGE") }}</h4>
                    <h4 class="hint">
                        {{ lang.get("DO_NOT_CLOSE") }}
                    </h4>
                </div>
                <div class="modal_block">
                    <div class="several_inputs_container">
                        <div class="wrapper_input">
                            <div class="input_title">BRNG</div>
                            <div class="input">
                                <div class="label">
                                    <div
                                        class="network-icon"
                                        :class="{
                                            icon1: blockchainA.SYMBOL === 'ETH',
                                            icon2: blockchainA.SYMBOL === 'BSC',
                                            icon3: blockchainA.SYMBOL === 'HARM',
                                        }"
                                    ></div>
                                    <div class="label_text">{{ blockchainA.NAME }}</div>
                                </div>
                            </div>
                        </div>
                        <i class="i-arrow-to"></i>
                        <div class="wrapper_input">
                            <div class="input_title"><span>BRNG</span></div>
                            <div class="input">
                                <div class="label">
                                    <div
                                        class="network-icon"
                                        :class="{
                                            icon1: blockchainB.SYMBOL === 'ETH',
                                            icon2: blockchainB.SYMBOL === 'BSC',
                                            icon3: blockchainB.SYMBOL === 'HARM',
                                        }"
                                    ></div>
                                    <div class="label_text">{{ blockchainB.NAME }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div @click="$emit('closeModal')" class="wrapper_input">
                        <div class="input_title">{{ lang.get("ADDRESS_IN") }} {{ blockchainB.NAME }}</div>
                        <div class="input">
                            <div class="label">
                                <!-- <div class="label_img"></div> -->
                                <i class="i-wallet"></i>
                                <div class="label_text">{{ receiverAddress }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="approveSuccess" class="modal_wrapper">
            <div class="modal_header">
                <div class="title">{{ lang.get("APPROVED_SWAP") }}</div>
                <!-- <button @click="$emit('close')" class="button_modal_close">
                    <i class="i-close"></i>
                </button> -->
            </div>
            <div class="modal_main">
                <div class="modal_block">
                    <div class="modal_main_icon">
                        <i class="i-succes"></i>
                    </div>
                    <div class="common_amount">
                        <span>{{ sendAmount }}</span
                        ><span> BRNG</span>
                    </div>
                    <a :href="getBlockchainExplorer(transactionHash)" target="_blank" class="view_tx"
                        ><span>{{ lang.get("VIEW_TX") }}</span></a
                    >
                </div>
            </div>
        </div>
        <div v-else-if="successTransaction" class="modal_wrapper">
            <div class="modal_header">
                <div class="title">{{ lang.get("SENDING_SWAP") }}</div>
                <!-- <button @click="$emit('close')" class="button_modal_close">
                    <i class="i-close"></i>
                </button> -->
            </div>
            <div class="modal_main">
                <div class="modal_block">
                    <div class="modal_main_icon">
                        <i class="i-succes"></i>
                    </div>
                    <div class="common_amount">
                        <span> Locked: {{ sendAmount }}</span
                        ><span> BRNG</span>
                    </div>
                    <a :href="getBlockchainExplorer(transactionHash)" target="_blank" class="view_tx"
                        ><span>{{ lang.get("VIEW_TX") }}</span></a
                    >
                    <div class="done-swap">
                        <p>Tokens Locked</p>
                        <span class="done-icon"></span>
                    </div>
                    <div class="done-swap">
                        <p v-if="Number(blockchainB.CHAIN_ID) !== Number(this.currentChain)">Switching Network to claim tokens..</p>
                        <p v-else="blockchainB">Network Switched</p>
                        <span v-if="Number(blockchainB.CHAIN_ID) === Number(this.currentChain)" class="done-icon"></span>
                    </div>

                    <button
                        v-if="Number(blockchainB.CHAIN_ID) !== Number(this.currentChain)"
                        @click="changeNetwork(blockchainB)"
                        class="button button_add_tokens_in_wallet"
                    >
                        Switch network
                    </button>
                    <button v-else @click="$emit('claimLock')" :disabled="disabled" class="button button_add_tokens_in_wallet">Claim tokens</button>
                </div>
                <div class="modal_block">
                    <div class="several_inputs_container">
                        <div class="wrapper_input">
                            <div class="input_title">BRNG</div>
                            <div class="input">
                                <div class="label">
                                    <div
                                        class="network-icon"
                                        :class="{
                                            icon1: blockchainA.SYMBOL === 'ETH',
                                            icon2: blockchainA.SYMBOL === 'BSC',
                                            icon3: blockchainA.SYMBOL === 'HARM',
                                        }"
                                    ></div>
                                    <div class="label_text">{{ blockchainA.NAME }}</div>
                                </div>
                            </div>
                        </div>
                        <i class="i-arrow-to"></i>
                        <div class="wrapper_input">
                            <div class="input_title"><span>BRNG</span></div>
                            <div class="input">
                                <div class="label">
                                    <div
                                        class="network-icon"
                                        :class="{
                                            icon1: blockchainB.SYMBOL === 'ETH',
                                            icon2: blockchainB.SYMBOL === 'BSC',
                                            icon3: blockchainB.SYMBOL === 'HARM',
                                        }"
                                    ></div>
                                    <div class="label_text">{{ blockchainB.NAME }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div @click="$emit('closeModal')" class="wrapper_input">
                        <div class="input_title">{{ lang.get("RECEIVER_ADDRESS_ON_BLOCKCHAIN") }} {{ blockchainB.NAME }}</div>
                        <div class="input">
                            <div class="label">
                                <i class="i-wallet"></i>
                                <div class="label_text">{{ receiverAddress }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="claimSuccess" class="modal_wrapper">
            <div class="modal_header">
                <div class="title">{{ lang.get("TAKE_AWAY_CLAIM_TITLE") }}</div>
                <button @click="$emit('closeClaimNotif')" class="button_modal_close">
                    <i class="i-close"></i>
                </button>
            </div>
            <div class="modal_main">
                <div class="modal_block">
                    <div class="modal_main_icon">
                        <i class="i-succes"></i>
                    </div>
                    <div class="common_amount">
                        <span> Claimed: {{ selectedClaim.event_obj.amount }}</span
                        ><span> BRNG</span>
                    </div>
                    <a :href="getBlockchainExplorer(claimTnx)" target="_blank" class="view_tx"
                        ><span>{{ lang.get("VIEW_TX") }}</span></a
                    >
                    <div class="done-swap">
                        <p>Tokens Locked</p>
                        <span class="done-icon"></span>
                    </div>
                    <div class="done-swap">
                        <p>Network Changed</p>
                        <span class="done-icon"></span>
                    </div>
                    <div class="done-swap">
                        <p>Tokens Claimed</p>
                        <span class="done-icon"></span>
                    </div>
                    <!-- <h4>Add BRNG on {{blockchainB.NAME}} in your wallet</h4>
                        <button :disabled="disableConnectButton" @click="addTokenToWallet()" class="button button_add_tokens_in_wallet">Add BRNG to {{blockchainB.NAME}}</button> -->
                </div>
                <div class="modal_block">
                    <div class="several_inputs_container">
                        <div class="wrapper_input">
                            <div class="input_title">BRNG</div>
                            <div class="input">
                                <div class="label">
                                    <div
                                        class="network-icon"
                                        :class="{
                                            icon1: selectedClaim.from_chain === 'ETH',
                                            icon2: selectedClaim.from_chain === 'BSC',
                                            icon3: selectedClaim.from_chain === 'HARM',
                                        }"
                                    ></div>
                                    <div class="label_text">{{ getBlockchainName(selectedClaim.from_chain) }}</div>
                                </div>
                            </div>
                        </div>
                        <i class="i-arrow-to"></i>
                        <div class="wrapper_input">
                            <div class="input_title"><span>BRNG</span></div>
                            <div class="input">
                                <div class="label">
                                    <div
                                        class="network-icon"
                                        :class="{
                                            icon1: selectedClaim.to_chain === 'ETH',
                                            icon2: selectedClaim.to_chain === 'BSC',
                                            icon3: selectedClaim.to_chain === 'HARM',
                                        }"
                                    ></div>
                                    <div class="label_text">{{ getBlockchainName(selectedClaim.to_chain) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div @click="$emit('closeModal')" class="wrapper_input">
                        <div class="input_title">{{ lang.get("RECEIVER_ADDRESS_ON_BLOCKCHAIN") }} {{ getBlockchainName(currentChain) }}</div>
                        <div class="input">
                            <div class="label">
                                <i class="i-wallet"></i>
                                <div class="label_text">{{ selectedClaim.event_obj.recipient }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const timer = (ms) => {
        return new Promise((res) => setTimeout(res, ms));
    };
    import { mapState } from "vuex";
    import MultiLang from "../../core/multilang.js";
    import conf from "../../Config.json";

    export default {
        name: "TransferingModal",
        props: [
            "blockchainA",
            "blockchainB",
            "receiverAddress",
            "sendAmount",
            "successTransaction",
            "pendingTransaction",
            "transactionHash",
            "approveSuccess",
            "approvePending",
            "claimSuccess",
            "claimTnx",
            "selectedClaim",
            "disabled",
        ],
        data: function () {
            return {
                disableConnectButton: false,
                tokenARate: 0,
                tokenBRate: 0,
                lang: new MultiLang(this),
            };
        },
        computed: {
            ...mapState(["currentChain"]),
        },
        methods: {
            async connectWallet() {
                try {
                    this.disableConnectButton = true;
                    // Will open the MetaMask UI
                    // You should disable this button while the request is pending!
                    await window.ethereum.request({ method: "eth_requestAccounts" });
                    this.disableConnectButton = false;
                    this.$emit("closeModal");
                    this.$store.commit("push_notification", {
                        type: "success",
                        typeClass: "success",
                        message: "Wallet has been connected",
                    });
                } catch (error) {
                    console.error(error);
                    this.$store.commit("push_notification", {
                        type: "warning",
                        typeClass: "danger",
                        message: "Something went wrong, please try again",
                    });
                    this.disableConnectButton = false;
                }
            },

            async addTokenToWallet() {
                const tokenAddress = conf[this.currentChain].BRNG_TOKEN_ADDRESS;
                const tokenSymbol = "BRNG";
                const tokenDecimals = 18;
                const tokenImage = "http://placekitten.com/200/300";

                try {
                    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
                    const wasAdded = await ethereum.request({
                        method: "wallet_watchAsset",
                        params: {
                            type: "ERC20", // Initially only supports ERC20, but eventually more!
                            options: {
                                address: tokenAddress, // The address that the token is at.
                                symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                                decimals: tokenDecimals, // The number of decimals in the token
                                image: tokenImage, // A string url of the token logo
                            },
                        },
                    });

                    if (wasAdded) {
                        console.log("Thanks for your interest!");
                    } else {
                        console.log("Your loss!");
                    }
                } catch (error) {
                    console.log(error);
                }
            },

            async changeNetwork(blockchain) {
                await this.$root.core.changeNetwork(this, blockchain.SYMBOL);
                await timer(2000);
            },

            getBlockchainExplorer(trxHash) {
                if (this.currentChain === 56) {
                    return `https://bscscan.com/tx/${trxHash}`;
                } else if (this.currentChain === 1666600000) {
                    return `https://explorer.harmony.one/tx/${trxHash}`;
                } else if (this.currentChain === 1) {
                    return `https://etherscan.io/tx/${trxHash}`;
                }
            },
            getBlockchainName(chainId) {
                return chainId === 1 || chainId === "ETH"
                    ? "Ethereum"
                    : chainId === 56 || chainId === "BSC"
                    ? "Binance smart chain"
                    : chainId === 166660000 || chainId === "HARM"
                    ? "Harmony"
                    : "";
            },
        },
    };
</script>
