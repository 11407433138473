<template>
    <button class="button button_lang">
        <div class="icon_lang icon_lang_" :class="{ icon_lang_en: setActiveLang('en'), icon_lang_ru: setActiveLang('ru') }"></div>
        <ul class="drop_down lang_list">
            <li class="drop_down-item">
                <button @click="setLang('en', $event)" :class="{ active: setActiveLang('en') }">
                    <div class="icon_lang icon_lang_en"></div>
                    <span>English</span>
                </button>
            </li>
            <li class="drop_down-item">
                <button @click="setLang('ru', $event)" :class="{ active: setActiveLang('ru') }">
                    <div class="icon_lang icon_lang_ru"></div>
                    <span>Russian</span>
                </button>
            </li>
        </ul>
    </button>
</template>

<script>
    import MultiLang from "../../core/multilang.js";
    export default {
        data: function () {
            return {
                lang: new MultiLang(this),
                selectedLang: "",
            };
        },
        mounted() {
            this.selectedLang = localStorage.getItem("lang");
        },
        methods: {
            setLang(lang, event) {
                this.selectedLang = lang;
                this.$store.dispatch("updateLanguage", lang);
                // this.$root.core.setLangForAddress(lang);
            },

            setActiveLang(inputLang) {
                return inputLang === this.selectedLang;
            },

            setFlagImg() {
                let lang = localStorage.getItem("lang");
                return lang ? `${lang}Icon` : "enIcon";
            },

            getLang() {
                return localStorage.getItem("lang");
            },
        },
    };
</script>
