<template>
    <div>
        <div v-if="showConnectWallet" class="modal">
            <div class="modal_wrapper">
                <div class="modal_header">
                    <div class="title">Connect your wallet</div>
                    <button @click="$emit('close')" class="button_modal_close">
                        <i class="i-close"></i>
                    </button>
                </div>
                <div class="modal_main">
                    <div class="modal_block">
                        <div class="modal_main_icon">
                            <i class="i-danger"></i>
                        </div>
                        <h4>{{ lang.get("CONNECT_WALLET_DESCRIPTION") }}</h4>
                        <button :disabled="disableConnectButton" @click="connectWallet()" class="button button_connect-wallet">
                            {{ lang.get("CONNECT_WALLET") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showNetworkSwitch" class="modal">
            <div class="modal_wrapper">
                <div class="modal_header">
                    <div class="title">Change current network</div>
                    <button @click="$emit('close')" class="button_modal_close">
                        <i class="i-close"></i>
                    </button>
                </div>
                <div class="modal_main">
                    <div class="modal_block">
                        <div class="modal_main_icon">
                            <i class="i-danger"></i>
                        </div>
                        <h4>Please change current network to {{ blockchainA.NAME }}</h4>
                        <button @click="changeNetwork(blockchainA.SYMBOL)" class="button button_connect-wallet">
                            Change network to {{ blockchainA.NAME }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MultiLang from "../../core/multilang.js";
    import conf from "../../Config.json";
    var Config = conf[Number(window?.ethereum?.chainId) || conf.PRIMARY_BLOCKCHAIN.chainId];
    export default {
        props: ["showConnectWallet", "showNetworkSwitch", "blockchainA"],
        data: function () {
            return {
                disableConnectButton: false,
                lang: new MultiLang(this),
            };
        },
        computed: {
            // disableButton() {
            //     if (this.blockchainA && this.blockchainA.chainId === "0x3") {
            //         return true;
            //     }
            // },
        },
        methods: {
            async connectWallet() {
                try {
                    this.disableConnectButton = true;
                    // Will open the MetaMask UI
                    // You should disable this button while the request is pending!
                    await window.ethereum.request({ method: "eth_requestAccounts" });
                    this.disableConnectButton = false;
                    this.$emit("close");
                    this.$store.commit("push_notification", {
                        type: "success",
                        typeClass: "success",
                        message: "Wallet has been connected",
                    });
                } catch (error) {
                    this.$store.commit("push_notification", {
                        type: "warning",
                        typeClass: "danger",
                        message:
                            error.message && error.message == "Already processing eth_requestAccounts. Please wait."
                                ? "Please proceed to your metamask extention to connect the wallet"
                                : "Something went wrong, please try again",
                    });
                    this.disableConnectButton = false;
                }
            },
            async changeNetwork(blockchain) {
                await this.$root.core.changeNetwork(this, blockchain);
            },
        },
    };
</script>
